import { Component, Input, OnInit } from "@angular/core"
import { OrderProduct } from '../../../interfaces/order-product.interface'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrderProductDetailsModalComponent } from '../order-product-details-modal/order-product-details-modal.component'

@Component({
  selector: "[app-order-product]",
  templateUrl: "./order-product.component.html",
  styleUrls: ["./order-product.component.css"],
})
export class OrderProductComponent implements OnInit {
  @Input() product: OrderProduct
  @Input() currency: string

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  showDetails() {
    const modalRef = this.modalService.open(OrderProductDetailsModalComponent, {
      centered: true,
    })

    modalRef.componentInstance.product = this.product
    modalRef.componentInstance.currency = this.currency
  }
}
