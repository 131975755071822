import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DishComponent } from './dish.component';
import { AddDishComponent, DishListComponent, EditDishComponent } from './index';

const routes: Routes = [
  { 
    path: '', 
    component: DishComponent,
    children: [
      {
        path: 'add-dish', 
        component: AddDishComponent,
      },
      {
        path: 'dish-list', 
        component: DishListComponent,
      },
      {
        path: 'edit-dish', 
        component: EditDishComponent,
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DishRoutingModule { }
