import { Component, OnInit } from "@angular/core";
import { IoService } from "../../services/http/io.service";
import { Menu } from '../interfaces/menu.interface';

@Component({
  selector: "app-menu-list",
  templateUrl: "./menu-list.component.html",
  styleUrls: ["./menu-list.component.css"],
})
export class MenuListComponent implements OnInit {
  menus: Menu[] = []
  selectedRestaurantIndex: number = -1

  constructor(
    private io: IoService,
  ) { }

  ngOnInit(): void {
    this.fetchAllRestaurants()
  }

  async fetchAllRestaurants() {
    const restaurantId = JSON.parse(localStorage.getItem('restaurant')).id

    this.menus = (await this.io.apicall(null, `menu/portal/restaurant/${restaurantId}`, 'get')) as Menu[]
  }
}
