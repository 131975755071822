import { Component, Input, OnInit } from "@angular/core";
import { MenuProduct } from '../../../interfaces/menu-product.interface';
import { MenuFetchProductService } from '../../../services/menu-fetch-product.service';
import { SubProduct } from '../../../interfaces/sub-product.interface';

@Component({
  selector: "app-menu-product",
  templateUrl: "./menu-product.component.html",
  styleUrls: ["./menu-product.component.css"],
})
export class MenuProductComponent implements OnInit {
  @Input() product: MenuProduct

  subProducts: SubProduct[] = []
  showSubProducts = false

  constructor(
    private menuFetchProductService: MenuFetchProductService,
  ) { }

  ngOnInit(): void {
  }

  toggleSubProductList() {
    this.showSubProducts = !this.showSubProducts

    if (this.showSubProducts) {
      this.getSubProducts()
    }
  }

  linkAreaClickHandler(event) {
    event.stopPropagation();
  }

  async getSubProducts() {
    const res = await this.menuFetchProductService.getProduct(this.product.mepProductId)

    this.subProducts = res.subProducts;
  }
}
