import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IoService } from '../../services/http/io.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';


@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  VerificationFrm: FormGroup;
  closeResult: any;
  storeEmail: any = '';
  message: any = '';

  constructor(
    private ar: ActivatedRoute,
    private fb: FormBuilder,
    private io: IoService,
    private router: Router,
    private modalService: NgbModal,
    private toastService: ToastrService,
  ) {

  }

  ngOnInit(): void {
    this.loadFrm();
    this.fetchVerficationCode();
  }

  loadFrm() {
    this.VerificationFrm = this.fb.group({
      code: ['', Validators.compose([Validators.required])]
    })
  }

  fetchVerficationCode() {
    this.ar.queryParams.subscribe((res: any) => {
      if (res) {
        this.VerificationFrm.get('code').setValue(res['code']);
      }
    });
  }


  submit(Obj, content) {
    let payLoad = {
      encryptedText: Obj.code
    }
    this.io.apicall(payLoad, 'restaurantowner/verify-email', 'post').then((res: any) => {
      if (res['serverResponse'].code === 200) {
        this.message = this.io.data_.serverResponse.message
        this.toastService
          .success(
            res['serverResponse'].message, '',
            { positionClass: 'toast-top-center', closeButton: true }
          );
        this.storeEmail = res['result'].userData?.email;
        this.router.navigate(['/login'])
      }
    }).catch(err => {
      this.message = this.io.data_.serverResponse.message
      if (err.error['serverResponse'].code === 500) {
        this.modalService
          .open(content, { ariaLabelledBy: "modal-basic-title" })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              return reason;
            }
          );
      }
      if (err.error['serverResponse'].code != 500) {
        this.toastService
          .error(
            err.error['serverResponse'].message, '',
            { positionClass: 'toast-top-center', closeButton: true }
          );
      }
    })
  }


}
