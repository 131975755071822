import { Component, Input, OnInit } from "@angular/core"
import { OrderProduct } from '../../../interfaces/order-product.interface'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: "app-order-product-details-modal",
  templateUrl: "./order-product-details-modal.component.html",
  styleUrls: ["./order-product-details-modal.component.css"],
})
export class OrderProductDetailsModalComponent implements OnInit {
  @Input() product: OrderProduct
  @Input() currency: string

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
}
