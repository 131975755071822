import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { data } from "jquery";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: "/dashboard", title: "Dashboard", icon: "design_app", class: "" },
  { path: "/profile", title: "Profile", icon: "users_single-02", class: "" },
  {
    path: "/dish/add-dish",
    title: "Add Menu Item",
    icon: "design_palette",
    class: "",
  },
  {
    path: "/dish/dish-list",
    title: "Menu items",
    icon: "loader_gear",
    class: "",
  },
  {
    path: "/menu/menu-list",
    title: "Menu Management",
    icon: "design_palette",
    class: "",
  },
  {
    path: "/branch/branch-list",
    title: "Branch",
    icon: "business_globe",
    class: "",
  },
  { path: "/order/order-list", title: "Order", icon: "files_box", class: "" },
  {
    path: "/transaction/transaction-payout",
    title: "Transaction",
    icon: "shopping_box",
    class: "",
  },
  {
    path: "/login",
    title: "Logout",
    icon: "now-ui-icons media-1_button-power",
    class: "",
  },
];

export const ROUTES1: RouteInfo[] = [
  { path: "/dashboard", title: "Dashboard", icon: "design_app", class: "" },
  { path: "/profile", title: "Profile", icon: "users_single-02", class: "" },
  {
    path: "/dish/add-dish",
    title: "Add Menu Item",
    icon: "design_palette",
    class: "",
  },
  {
    path: "/dish/dish-list",
    title: "Menu Items",
    icon: "loader_gear",
    class: "",
  },
  {
    path: "/menu/menu-list",
    title: "Menu Management",
    icon: "design_palette",
    class: "",
  },
  { path: '/branch/branch-list', title: 'Branch', icon: 'business_globe', class: '' },
  { path: "/order/order-list", title: "Order", icon: "files_box", class: "" },
  {
    path: "/transaction/transaction-payout",
    title: "Transaction",
    icon: "shopping_box",
    class: "",
  },
  {
    path: "/login",
    title: "Logout",
    icon: "now-ui-icons media-1_button-power",
    class: "",
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private router: Router, private ioservice: IoService) {}

  ngOnInit() {
    let data: any = JSON.parse(localStorage.getItem("restaurant"));
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    if (data.parentId == "") {
      this.menuItems = ROUTES.filter((menuItem) => menuItem);
    } else {
      this.menuItems = ROUTES1.filter((menuItem) => menuItem);
    }
  }
  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }

  logOut() {
    var token: any = JSON.parse(localStorage.getItem("refreashToken"));
    let payload = {
      refreshToken: token,
    };
    this.ioservice
      .apicall(payload, "restaurantowner/logout", "post")
      .then((res) => {
        if (this.ioservice.data_.serverResponse.code === 200) {
          localStorage.clear();
          this.router.navigate(["/login"]);
        }
      })
      .catch((err) => {
        localStorage.clear();
        this.router.navigate(["/login"]);
      });
  }
}
