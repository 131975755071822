import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { rejects } from 'assert';
import { NgxUiLoaderService } from "ngx-ui-loader";  

@Injectable({
  providedIn: 'root'
})
export class IoService {
  private baseUrl = environment.baseUrl;
  data_: any;
  userToken: any = '';
  loader: boolean = false;
  icon:any;
  reason:any;
  private messageSource = new BehaviorSubject(' ');
  currentMessage = this.messageSource.asObservable();

  constructor(
    public http: HttpClient,
    private uiLoader:NgxUiLoaderService
  ) { }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  apicall(
    body_params: any,
    request_path: any,
    action_type: String = "post"
  ) {
    return new Promise((resolve,reject) => {

      let url = this.baseUrl + request_path;

      if (action_type == "get") {
        this.http.get(url).subscribe(
          data => {
            this.uiLoader.start();
            this.data_ = data;
            resolve(this.data_);
            this.uiLoader.stop();
          },
          err => {
            this.data_ = err.error;
            reject(err);
          }
        );
      }

      if (action_type == "post") {
        this.http.post(url, body_params).subscribe(
          data => {
            this.uiLoader.start();
            this.data_ = data;
            resolve(this.data_);
            this.uiLoader.stop();
          },
          err => {
            this.data_ = err.error;
            reject(err);
          }
        );
      }


      if (action_type == "file") {
        let headers = new HttpHeaders();

        // headers = headers.set("Content-Type", 'multipart/form-data');
        // headers.append('Accept', 'application/json');
        // headers.append('Access-Control-Allow-Origin ','*');
        // headers.append('Access-Control-Allow-Credentials', 'true');
        // headers.append('Access-Control-Allow-Methods', 'OPTION,POST');
        // let options = new RequestOptions({ headers: headers });

        this.http.post(url, body_params, { headers: headers }).subscribe(
          data => {
            this.uiLoader.start();
            this.data_ = data;
            resolve(this.data_);
            this.uiLoader.stop();
          },
          err => {
            this.data_ = err.error;
            resolve(this.data_);
          }
        );
      }

    });
  }

  alert(msg, type, time = 3000) {
    this.reason = msg;
    this.icon = 'puff';
    if (msg == 'Loading...') {
      this.loader = true;
      setTimeout(() => {
        this.loader = false;
      }, 10000);
    } else {
      $('.alert:first').fadeOut();
      var htx = `<div class="alert alert-` + type + ` my-2" role="alert">` + msg + `</div>`;
      $('.alertPlace').append(htx).fadeIn();
      setTimeout(() => {
        $('.alert:last').remove().fadeOut();
      }, time);
    }
  }
}
