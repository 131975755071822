import { Component, Input, OnInit } from "@angular/core"
import { MenuAvailability } from '../../../interfaces/menu-availability.interface'
import weekDays from './week-days'

@Component({
  selector: "app-menu-availability",
  templateUrl: "./menu-availability.component.html",
  styleUrls: ["./menu-availability.component.css"],
})
export class MenuAvailabilityComponent implements OnInit {
  @Input() availability: MenuAvailability

  weekDayName: string = ''

  constructor(
  ) { }

  ngOnInit(): void {
    this.weekDayName = weekDays[this.availability.dayOfWeek]
  }
}
