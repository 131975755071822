import { Component, Input, OnInit } from "@angular/core";
import { SubProduct } from '../../../interfaces/sub-product.interface';

@Component({
  selector: "app-sub-product-list",
  templateUrl: "./sub-product-list.component.html",
  styleUrls: ["./sub-product-list.component.css"],
})
export class SubProductListComponent implements OnInit {
  @Input() subProducts: SubProduct[]

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
