import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { MapsAPILoader } from "@agm/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "../../services/data-routing/data.service";
import { ValidatorsService } from "../../services/validators/validators.service";
import { Subscription } from "rxjs";
declare var google: any;
@Component({
  selector: "app-branch-details",
  templateUrl: "./branch-details.component.html",
  styleUrls: ["./branch-details.component.css"],
})
export class BranchDetailsComponent implements OnInit, OnDestroy {
  image: any = "";
  logoimage: any = "";
  deliveryToggleBtn: boolean = false;
  pickupToggleBtn: boolean = false;
  maxAmount_: any;
  slavMaxDistance_: any;
  slavRangeForm_: any = 0;
  slavRangeTo_: any;
  slavDeliveryAmount_: any;
  deliverySlav: any = [];
  endDeliverySlavIndex = 0;
  restaurantid: any;
  locationName: any;
  lat: any;
  lng: any;
  @ViewChild("search") search: ElementRef;
  editBranchFrm: FormGroup;
  countries: any;
  onlyCheck: any = "";
  branchCheck: any = "";
  editBranchViewResult: any;
  resObj: any;
  values: any;
  branchId: any;
  selectedCountry: any = "";
  countryCode: any = "";
  paramObserver: Subscription;
  constructor(
    private io: IoService,
    private ngZone: NgZone,
    public location: Location,
    private mapsAPILoader: MapsAPILoader,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private data: DataService,
    private ValidatorsService: ValidatorsService,
    private route: ActivatedRoute
  ) {
    this.editBranchFrm = this.fb.group({
      branchName_: [null, Validators.compose([Validators.required])],
      contactno_: [
        null,
        Validators.compose([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "phone"
          ),
        ]),
      ],
      email_: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      city_: [null, Validators.compose([Validators.required])],
      address_: [null, Validators.compose([Validators.required])],
      landmark_: [null, Validators.compose([Validators.required])],
      status_: [null, Validators.compose([Validators.required])],
      dishcount_: [null, Validators.compose([Validators.required])],
      branchdetails_: [null, Validators.compose([Validators.required])],
      latitude: [""],
      longitude: [""],
      addCommissionRate: ["", Validators.compose([Validators.required])],
      countryType: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.branchId = id;
        this.branchView();
      } else {
        this.router.navigate(["/branch/branch-list"]);
      }
    });
    this.getLoc();
    let resObj = JSON.parse(localStorage.getItem("restaurant"));
    this.restaurantid = resObj?.id;

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement,
        { types: [] }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: typeof google.maps.places.PlaceResult =
            autocomplete.getPlace();
          if (!place.place_id) {
            window.alert("Please select an option from the dropdown list.");
            return;
          }
          this.locationName = place.name;
          // verify result //
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom //
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.editBranchFrm.patchValue({
            address_: this.locationName,
            latitude: this.lat,
            longitude: this.lng,
          });
          //this.zoom = 12;
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  getLoc() {
    this.http.get<any>("./assets/data/country.json").subscribe((data) => {
      this.countries = data.countries;
      if (this.countries?.length == 1) {
        this.selectedCountry = this.countries[0].name;
        this.countryCode = this.countries[0].code;
        this.editBranchFrm.patchValue({ countryType: this.selectedCountry });
      }
    });
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(
      (item) => item.name === this.selectedCountry
    );
    this.countryCode = index[0].code;
  }

  landmark(event: any) {
    let mystring = this.editBranchViewResult.restaurantName.split("-");
    if (event.target.value.length != 0) {
      this.values = mystring[0] + "-" + event.target.value;
    } else {
      this.values = mystring[0];
    }
  }

  branchView() {
    let payload = {
      restaurantId: this.branchId,
    };
    this.io
      .apicall(payload, "restaurant/restaurant-view", "post")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          this.editBranchViewResult = res["result"][0];
          let storeResult = res["result"][0];
          let selectedCountry = this.editBranchViewResult.country;
          let index = this.countries.filter(
            (item) => item.name === selectedCountry
          );
          if (index.length > 0) {
            this.countryCode = index[0].code;
          }

          this.slavMaxDistance_ = storeResult.maxDistCoveredByRestaurant;
          this.deliveryToggleBtn = storeResult.isDeliveryAvaliable;
          this.pickupToggleBtn = storeResult.isPickupAvaliable;
          this.branchCheck = this.editBranchViewResult?.IsNew;
          this.onlyCheck = this.editBranchViewResult?.isOnly;
          this.image = this.editBranchViewResult.imageUrl;
          this.logoimage = this.editBranchViewResult.restaurantLogo;
          this.editBranchFrm.patchValue({
            branchName_: this.editBranchViewResult.restaurantName,
            contactno_: this.editBranchViewResult.contactNo,
            email_: this.editBranchViewResult.email,
            city_: this.editBranchViewResult.city,
            status_: this.editBranchViewResult?.isActive
              ? "Active"
              : "Inactive",
            address_: this.editBranchViewResult.address,
            landmark_: this.editBranchViewResult.landmark,
            dishcount_: this.editBranchViewResult.numberOfDish,
            branchdetails_: this.editBranchViewResult.restaurantDetails,
            latitude: this.editBranchViewResult.latitude,
            longitude: this.editBranchViewResult.longitude,
            addCommissionRate: this.editBranchViewResult.commissionRate,
            countryType:
              this.countries?.length == 1
                ? this.selectedCountry
                : this.editBranchViewResult.country,
            countryCode: this.countryCode,
            password_: this.editBranchViewResult.passWord,
          });
          this.editBranchFrm.markAllAsTouched();

          this.deliverySlav = [];
          for (let slav of storeResult.deliverySlav) {
            this.deliverySlav.push({
              rangeFrom: slav.rangeFrom,
              rangeTo: slav.rangeTo,
              deliveryAmount: slav.deliveryAmount,
            });
          }
          this.endDeliverySlavIndex = this.deliverySlav.length - 1;

          if (this.deliverySlav.length > 0) {
            this.slavRangeForm_ =
              this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  toggleCheckIsOnly(event) {
    this.onlyCheck = event.target.checked;
  }
  toggleCheckBranch(event) {
    this.branchCheck = event.target.checked;
  }

  editBranch(value) {
    if (!this.editBranchFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editBranchFrm.markAllAsTouched();
      return;
    }
    let payload = {
      restaurantId: this.branchId,
      restaurantName: value.branchName_,
      contactNo: value.contactno_.toString(),
      email: value.email_,
      city: value.city_,
      country: value.countryType,
      countryCode: this.countryCode,
      address: value.address_,
      landmark: value.landmark_,
      latitude: value.latitude,
      longitude: value.longitude,
      imageUrl: this.image,
      restaurantLogo: this.logoimage,
      IsNew: this.branchCheck ? this.branchCheck : false,
      isOnly: this.onlyCheck ? this.onlyCheck : false,
      restaurantDetails: value.restaurantdetails_,
      commissionRate: value.addCommissionRate,
    };
    this.io
      .apicall(payload, "restaurant/edit-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  activeInactive(obj) {
    let payLoad;
    if (obj.isActive == true) {
      payLoad = {
        restaurantId: obj._id,
        isActive: false,
      };
    } else {
      payLoad = {
        restaurantId: obj._id,
        isActive: true,
      };
    }

    this.io
      .apicall(payLoad, "restaurant/change-restaurant-status", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.branchView();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  //SETTINGS REALTED WORK //

  // TOGGLE ON OFF FOR DELIVERY //
  toggleForDelivery(event) {
    this.deliveryToggleBtn = event.target.checked;
    let payload = {
      restaurantId: this.branchId,
      isDeliveryAvaliable: this.deliveryToggleBtn,
      isPickupAvaliable: this.pickupToggleBtn,
    };
    this.io
      .apicall(payload, "restaurant/set-delivery-or-pickup-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // TOGGLE ON OFF FOR PICKUP //
  toggleForPickup(event) {
    this.pickupToggleBtn = event.target.checked;
    let payload = {
      restaurantId: this.branchId,
      isDeliveryAvaliable: this.deliveryToggleBtn,
      isPickupAvaliable: this.pickupToggleBtn,
    };
    this.io
      .apicall(payload, "restaurant/set-delivery-or-pickup-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // ADD SLAV RANGE //
  addRangeSlav() {
    if (this.slavRangeForm_ > this.slavRangeTo_) {
      alert("RangeTo must be greater than RangeFrom");
      return false;
    }
    if (this.slavMaxDistance_ < this.slavRangeTo_) {
      alert("Your given delivery range is exceeded");
      return false;
    }
    this.deliverySlav.push({
      rangeFrom: this.slavRangeForm_,
      rangeTo: this.slavRangeTo_,
      deliveryAmount: this.slavDeliveryAmount_,
    });
    if (this.deliverySlav.length > 0) {
      this.slavRangeForm_ =
        this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
    }
    this.endDeliverySlavIndex = this.deliverySlav.length - 1;
    // this.slavRangeForm_ = "";
    this.slavRangeTo_ = "";
    this.slavDeliveryAmount_ = "";
  }

  // DELETE SLAV ITEM //
  deleteSlavRange(id: number) {
    this.deliverySlav.splice(
      this.deliverySlav.findIndex((abc) => abc.id === id),
      1
    );
    this.endDeliverySlavIndex = this.deliverySlav.length - 1;
    if (this.deliverySlav.length > 0) {
      this.slavRangeForm_ =
        this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
    } else if (this.deliverySlav.length == 0) {
      this.slavRangeForm_ = 0;
    }
  }

  // SLAV MAX DISTANCE //
  maxDistanceSave() {
    let payload = {
      restaurantId: this.branchId,
      maxDistCoveredByRestaurant: this.slavMaxDistance_,
    };
    this.io
      .apicall(payload, "restaurant/set-max-dist-covered-by-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          // swal.fire({
          //   title: "Success",
          //   text: res["serverResponse"].message,
          //   icon: "success",
          //   confirmButtonColor: "#442DFF;",
          //   confirmButtonText: "ok",
          // });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // ADD SLAV LIST //
  addSlavList() {
    if (
      this.slavMaxDistance_ !=
      this.deliverySlav[this.deliverySlav.length - 1].rangeTo
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you covered all ranges in your specified radius",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return false;
    } else {
      this.maxDistanceSave();
      let payload = {
        restaurantId: this.branchId,
        deliverySlav: this.deliverySlav,
      };
      this.io
        .apicall(payload, "restaurant/add-delivery-slav", "post")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            swal.fire({
              title: "Success",
              text: res["serverResponse"].message,
              icon: "success",
              confirmButtonColor: "#442DFF;",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          swal.fire({
            title: "Oops...",
            text: this.io.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        });
    }
  }
}
