import { NgbModal, NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import { Router, RouterLink } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import * as $ from "jquery";
import swal from "sweetalert2";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginFrm: FormGroup;
  submited: boolean = false;
  message: any;
  closeResult = "";
rejected: any;

  constructor(
    private router: Router,
    private ioService: IoService,
    private fb: FormBuilder,
    private toastService: ToastrService,
    config: NgbModalConfig,
    private modalService: NgbModal
  ) {
    config.backdrop = "static";
    config.keyboard = false;
  }

  loadFrm() {
    this.loginFrm = this.fb.group({
      email_: ["", Validators.compose([Validators.required])],
      password_: ["", Validators.compose([Validators.required])],
    });
  }

  get f() {
    return this.loginFrm.controls;
  }

  ngOnInit(): void {
    this.loadFrm();
  }

  sendEmail() {
    let payLoad = {
      email: this.loginFrm.value.email_,
    };
    this.ioService
      .apicall(payLoad, "restaurantowner/resend-email", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.modalService.dismissAll();
          return this.toastService.success(res["serverResponse"].message, "", {
            positionClass: "toast-top-center",
            closeButton: true,
          });
        }
      })
      .catch((err) => {
        return this.toastService.error(
          err.error["serverResponse"].message,
          "",
          { positionClass: "toast-top-center", closeButton: true }
        );
      });
  }

  login(Obj, content, rejected) {
    this.submited = true;
    let payLoad = {
      email: Obj.email_,
      password: Obj.password_,
    };
    this.ioService
      .apicall(payLoad, "restaurantowner/login", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          localStorage.setItem(
            "restaurant",
            JSON.stringify(res["result"].userData)
          );
          localStorage.setItem(
            "token",
            JSON.stringify(res["result"].tokens.accessToken)
          );
          localStorage.setItem(
            "refreashToken",
            JSON.stringify(res["result"].tokens.refreshToken)
          );
          this.router.navigate(["/dashbaord"]);
        }
      })
      .catch((err) => {
        this.message = err.error["serverResponse"].message;
        if (err.error["serverResponse"].code === 401) {
          this.modalService
            .open(content, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                return reason;
              }
            );
        }
        if (err.error["serverResponse"].code === 402) {
          swal.fire({
            title: "Oops...",
            text: this.ioService.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        if (err.error["serverResponse"].code === 403) {
          swal.fire({
            title: "Oops...",
            text: this.ioService.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        if (err.error["serverResponse"].code === 400) {
          swal.fire({
            title: "Oops...",
            text: this.ioService.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
        if (err.error["serverResponse"].code === 404) {
          this.modalService
            .open(rejected, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
              (result) => {
                this.closeResult = `Closed with: ${result}`;
              },
              (reason) => {
                return reason;
              }
            );
        }
      });
  }

  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  validateEmail(email_) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email_).toLowerCase());
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
