import { MapsAPILoader } from "@agm/core";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import { Location } from "@angular/common";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { ValidatorsService } from "../../services/validators/validators.service";
declare var google: any;
@Component({
  selector: "app-add-branch",
  templateUrl: "./add-branch.component.html",
  styleUrls: ["./add-branch.component.css"],
})
export class AddBranchComponent implements OnInit {
  image: any = "";
  addBranchFrm: FormGroup;
  restaurantid: any;
  locationName: any;
  lat: any;
  lng: any;
  countries: any;
  logoimage: any;
  @ViewChild("search") search: ElementRef;
  onlyCheck: any = "";
  branchCheck: any = "";
  addBranchViewResult: any;
  resObj: any;
  values = "";
  selectedCountry: any = "";
  countryCode: string = "";
  constructor(
    private io: IoService,
    private ngZone: NgZone,
    public location: Location,
    private mapsAPILoader: MapsAPILoader,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private ValidatorsService: ValidatorsService
  ) {
    this.addBranchFrm = this.fb.group({
      branchName_: [null, Validators.compose([Validators.required])],
      contactno_: [
        null,
        Validators.compose([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "phone"
          ),
        ]),
      ],
      email_: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      city_: [null, Validators.compose([Validators.required])],
      address_: [null, Validators.compose([Validators.required])],
      landmark_: [null, Validators.compose([Validators.required])],
      status_: [null, Validators.compose([Validators.required])],
      dishcount_: [null, Validators.compose([Validators.required])],
      branchdetails_: [null, Validators.compose([Validators.required])],
      latitude: [""],
      longitude: [""],
      addCommissionRate: ["", Validators.compose([Validators.required])],
      countryType: ["", Validators.compose([Validators.required])],
      password_: ["", Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.resObj = JSON.parse(localStorage.getItem("restaurant"));
    this.restaurantid = this.resObj?.id;
    this.getCountry();
    this.branchView();
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement,
        { types: [] }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: typeof google.maps.places.PlaceResult =
            autocomplete.getPlace();
          if (!place.place_id) {
            window.alert("Please select an option from the dropdown list.");
            return;
          }
          this.locationName = place.name;
          // verify result //
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom //
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.addBranchFrm.patchValue({
            address_: this.locationName,
            latitude: this.lat,
            longitude: this.lng,
          });
          //this.zoom = 12;
        });
      });
    });
  }

  getCountry() {
    this.http.get<any>("./assets/data/country.json").subscribe((data) => {
      this.countries = data.countries;
      if (this.countries?.length == 1) {
        this.selectedCountry = this.countries[0].name;
        this.countryCode = this.countries[0].code;
        this.addBranchFrm.patchValue({ countryType: this.selectedCountry });
      }
    });
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(
      (item) => item.name === this.selectedCountry
    );
    this.countryCode = index[0].code;
  }

  landmark(event: any) {
    if (event.target.value.length == 0) {
      this.values = this.addBranchViewResult.restaurantName;
      return false;
    }
    this.values =
      this.addBranchViewResult.restaurantName + "-" + event.target.value;
  }

  branchView() {
    let payload = {
      restaurantId: this.restaurantid,
    };
    this.io
      .apicall(payload, "restaurant/restaurant-view", "post")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          this.addBranchViewResult = res["result"][0];
          let storeResult = res["result"][0];

          let selectedCountry = this.addBranchViewResult.country;
          let index = this.countries.filter(
            (item) => item.name === selectedCountry
          );
          if (index.length > 0) {
            this.countryCode = index[0].code;
          }

          this.branchCheck = this.addBranchViewResult?.IsNew;
          this.onlyCheck = this.addBranchViewResult?.isOnly;
          this.image = this.addBranchViewResult.imageUrl;
          this.logoimage = this.addBranchViewResult.restaurantLogo;
          this.addBranchFrm.patchValue({
            branchName_: this.addBranchViewResult.restaurantName,
            // contactno_: this.addBranchViewResult.contactNo,
            // email_: this.addBranchViewResult.email,
            // city_: this.addBranchViewResult.city,
            status_: this.addBranchViewResult?.isActive ? "Active" : "Inactive",
            // address_: this.addBranchViewResult.address,
            // landmark_: this.addBranchViewResult.landmark,
            dishcount_: this.addBranchViewResult.numberOfDish,
            branchdetails_: this.addBranchViewResult.restaurantDetails,
            latitude: this.addBranchViewResult.latitude,
            longitude: this.addBranchViewResult.longitude,
            addCommissionRate: this.addBranchViewResult.commissionRate,
            countryType: this.addBranchViewResult.country,
            countryCode: this.countryCode,
            password_: this.addBranchViewResult.passWord,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  toggleCheckIsOnly(event) {
    this.onlyCheck = event.target.checked;
  }
  toggleCheckBranch(event) {
    this.branchCheck = event.target.checked;
  }

  addBranch() {
    if (!this.addBranchFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.addBranchFrm.markAllAsTouched();
      return;
    }
    let payload = {
      parentId: this.restaurantid,
      restaurantName: this.addBranchFrm.value.branchName_,
      contactNo: this.addBranchFrm.value.contactno_.toString(),
      email: this.addBranchFrm.value.email_,
      password: this.addBranchFrm.value.password_,
      city: this.addBranchFrm.value.city_,
      country: this.addBranchFrm.value.countryType,
      countryCode: this.countryCode,
      address: this.addBranchFrm.value.address_,
      landmark: this.addBranchFrm.value.landmark_,
      latitude: this.addBranchFrm.value.latitude,
      longitude: this.addBranchFrm.value.longitude,
      imageUrl: this.image,
      restaurantLogo: this.logoimage,
      IsNew: this.branchCheck ? this.branchCheck : false,
      isOnly: this.onlyCheck ? this.onlyCheck : false,
      restaurantDetails: this.addBranchFrm.value.branchdetails_,
      commissionRate: this.addBranchFrm.value.addCommissionRate,
      openingHours: [{}],
    };
    this.io
      .apicall(payload, "restaurant/create-restaurant-branch", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/branch/branch-list"]);
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }
}
