import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ProfileComponent } from './profile.component';
import { ProfileDetailsComponent } from './';

const routes: Routes = [
  { 
    path: '', 
    component: ProfileComponent,
    children: [
      {
        path: 'profile', 
        component: ProfileDetailsComponent,
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
