import { Component, Input, OnInit } from "@angular/core"
import { OrderProduct } from '../../../interfaces/order-product.interface'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { OrderProductDetailsModalComponent } from '../order-product-details-modal/order-product-details-modal.component'

@Component({
  selector: "app-order-product-details",
  templateUrl: "./order-product-details.component.html",
  styleUrls: ["./order-product-details.component.css"],
})
export class OrderProductDetailsComponent implements OnInit {
  @Input() product: OrderProduct
  @Input() currency: string

  showSubProducts: boolean = false

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }
}
