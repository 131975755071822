import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { parsePhoneNumber } from "libphonenumber-js/max";
import { parsePhoneNumber as parseMobileNumber } from "libphonenumber-js/mobile";

@Injectable({
  providedIn: "root",
})
export class ValidatorsService {
  constructor() {}
  validateWorkingHours(formControl) {
    if (formControl.value) {
      let err = formControl.value.findIndex(
        (item) =>
          item[Object.keys(item)[0]] != null &&
          (!item[Object.keys(item)[0]]?.from ||
            !item[Object.keys(item)[0]]?.to) /* ||
            item[Object.keys(item)[0]]?.to <= item[Object.keys(item)[0]]?.from */
      );
      return err != -1 ? { invalidHours: true } : null;
    } else {
      return null;
    }
  }
  validatePhoneNumber(getCode, type = "phone"): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const code = getCode();
      if (control.value) {
        if (!this.isValidNumber(code, control.value, type)) {
          return { invalidNumber: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  isValidNumber(code, number, type): boolean {
    if (number?.length <= 1) return false;
    number = code + number;
    let parsed;
    if (type == "mobile") {
      parsed = parseMobileNumber(number);
    } else {
      parsed = parsePhoneNumber(number);
    }
    return parsed.isValid();
  }
}
