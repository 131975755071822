import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import { MapsAPILoader } from "@agm/core";
import { Location } from "@angular/common";
import * as $ from "jquery";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { ValidatorsService } from '../../services/validators/validators.service';
declare var google: any;

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit {
  signupFrm: FormGroup;
  submited: boolean = false;
  locationName: any;
  lat: any;
  lng: any;
  countries: any;
  selectedCountry: any = "United Arab Emirates";
  countryCode: any = "+971";
  @ViewChild("search") search: ElementRef;
  submitted = false;
  IsplaceChange = false;
  placeId = null;
  constructor(
    public fb: FormBuilder,
    private io: IoService,
    private router: Router,
    private toastService: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private http: HttpClient,
    private ValidatorsService: ValidatorsService
  ) {
    this.signupFrm = this.fb.group({
      email_: [null, Validators.compose([Validators.required])],
      resturantName: [null, Validators.compose([Validators.required])],
      password_: [
        null,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/
          ),
        ]),
      ],
      country_: [null, Validators.compose([Validators.required])],
      city_: [null, Validators.compose([Validators.required])],
      address_: [null, Validators.compose([Validators.required])],
      phonenumber_: [
        null,
        Validators.compose([
          Validators.required,
          ValidatorsService.validatePhoneNumber(()=>this.countryCode,"phone"),
        ]),
      ],
      landmark_: [null, Validators.compose([Validators.required])],
      latitude: [null, Validators.compose([Validators.required])],
      longitude: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.getCountry();
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement,
        { types: [] }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result //
          const place: typeof google.maps.places.PlaceResult =
            autocomplete.getPlace();
          this.placeId = place.place_id;
          // console.log('place_id',place.place_id)
          // if (!place.place_id || place.place_id=='undefned' || place.place_id==null || place.place_id=='') {
          //   window.alert("Please select an option from the dropdown list.");
          //   return;
          // }
          this.locationName = place.name;
          // verify result //
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom //
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.signupFrm.patchValue({
            address_: this.locationName,
            latitude: this.lat,
            longitude: this.lng,
          });
          //this.zoom = 12;
        });
      });
    });
  }

  get f() {
    return this.signupFrm.controls;
  }

  getCountry() {
    this.http.get<any>("./assets/data/country.json").subscribe((data) => {
      this.countries = data.countries;
    });
  }

  changeCountry(event) {
    this.selectedCountry = event.target.value;
    let index = this.countries.filter(
      (item) => item.name === this.selectedCountry
    );
    this.countryCode = index[0].code;
  }

  signUp() {
    this.submitted = true;
    if (
      !this.placeId ||
      this.placeId == "undefned" ||
      this.placeId == null ||
      this.placeId == ""
    ) {
      // window.alert("Please select an option from the dropdown list.");
      // swal.fire({
      //   title: "Warning",
      //   text: 'Invailed Place. Please select an address only from the dropdown list.',
      //   icon: "warning",
      //   confirmButtonColor: "#442DFF;",
      //   confirmButtonText: "ok",
      // })
      this.signupFrm.patchValue({
        address_: null,
        // formControlName2: myValue2 (can be omitted)
      });
      return;
    }

    let payload = {
      email: this.signupFrm.value.email_,
      restaurantName: this.signupFrm.value.resturantName,
      password: this.signupFrm.value.password_,
      contactNo: this.signupFrm.value.phonenumber_.toString(),
      address: this.signupFrm.value.address_,
      restaurantDetails: "",
      restaurantLogo: "",
      country: this.signupFrm.value.country_,
      countryCode: this.countryCode,
      landmark: this.signupFrm.value.landmark_,
      latitude: this.signupFrm.value.latitude,
      longitude: this.signupFrm.value.longitude,
      city: this.signupFrm.value.city_,
    };
    this.io
      .apicall(payload, "restaurantowner/register", "post")
      .then((data) => {
        if (this.io.data_.serverResponse.code == 200) {
          this.toastService.success(this.io.data_.serverResponse.message, "", {
            positionClass: "toast-top-center",
            closeButton: true,
          });
          // this.submited = false;
          this.signupFrm.reset();
          this.router.navigate(["/login"]);
        }
      })
      .catch((err) => {
        if (this.io.data_.serverResponse.code === 400) {
          this.toastService.error(this.io.data_.serverResponse.message, "", {
            positionClass: "toast-top-center",
            closeButton: true,
          });
        }
      });
  }

  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }

  validateEmail(email_) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email_).toLowerCase());
  }
}
