import { Component, OnInit } from '@angular/core';
import { IoService } from '../../services/http/io.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor(public ioService: IoService) {
  }

  ngOnInit(): void {
  }

}
