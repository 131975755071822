import { environment } from "./../../environments/environment";
export const constant = {
  // no_profile_img: '../../../assets/images/no_profile_img.png',
  currency_: "AED",
};
export const routes = {
  dishesSetOrder: (id) =>
    `${environment.baseUrl}restaurant/${id}/dishes/set-order` /* 
  deleteDish: (id) => `${environment.baseUrl}dish/${id}`, */,
  getDish: `${environment.baseUrl}dish/dish-view`,
  getTagCategories: `${environment.baseUrl}tagcategories/fetch-tag-head`,
  getDietaryPreferencesCategories: `${environment.baseUrl}dietary-preference-categories`,

  getDietaryPreferencesOptions: (categoryId) =>
    `${environment.baseUrl}dietary-preference-categories/${categoryId}/dietary-preferences`,
};
