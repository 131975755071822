import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-tab-navigator",
  templateUrl: "./tab-navigator.component.html",
  styleUrls: ["./tab-navigator.component.css"],
})
export class TabNavigatorComponent implements OnInit {
  @Input() links: [];
  @Output() linksChange = new EventEmitter<number>();
  constructor() {}

  tabClick(index: number): void {
    this.linksChange.emit(index);
  }

  ngOnInit(): void {}
}
