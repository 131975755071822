import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { routes } from "../../_constant/constant";

@Injectable({
  providedIn: "root",
})
export class DishService {
  constructor(private http: HttpClient) {}
  /* delete(id: String): Observable<object> {
    return this.http.delete(routes.deleteDish(id));
  } */

  changeDishesOrder(dishes, restId): Observable<any> {
    return this.http.patch(routes.dishesSetOrder(restId), {
      dishes,
    });
  }

  getDish(id): Observable<any> {
    return this.http.post(routes.getDish, { dishId: id });
  }
}
