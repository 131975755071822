import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TagInputModule } from 'ngx-chips';
import { NgxPaginationModule } from 'ngx-pagination';
import { BranchRoutingModule } from './branch-routing.module';
import { BranchComponent } from './branch.component';
import {BranchDetailsComponent} from './branch-details/branch-details.component';
import {BranchListComponent} from './branch-list/branch-list.component';
import { AddBranchComponent } from './add-branch/add-branch.component';


@NgModule({
  declarations: [BranchComponent,BranchDetailsComponent,BranchListComponent, AddBranchComponent],
  imports: [
    CommonModule,
    BranchRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TagInputModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot()
  ]
})
export class BranchModule { }
