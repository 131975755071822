import { Component, Input, OnInit } from "@angular/core";
import { MenuAvailability } from '../../../interfaces/menu-availability.interface';

@Component({
  selector: "app-menu-availability-list",
  templateUrl: "./menu-availability-list.component.html",
  styleUrls: ["./menu-availability-list.component.css"],
})
export class MenuAvailabilityListComponent implements OnInit {
  @Input() menuAvailabilities: MenuAvailability[]

  showDetails = false

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
