import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { TransactionComponent } from "./transaction.component";
import { RestaurantTransactionPayoutViewDetailsComponent } from "./restaurant-transaction-payout-view-details/restaurant-transaction-payout-view-details.component";

const routes: Routes = [
  {
    path: "",
    component: TransactionComponent,
    children: [
      {
        path: "transaction-payout",
        component: RestaurantTransactionPayoutViewDetailsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransactionRoutingModule {}
