import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  forgetPasswordFrm: FormGroup;
  otpRecvied: any;
  constructor(
    private fb: FormBuilder,
    private io: IoService,
    private router: Router
  ) {
    this.forgetPasswordFrm = this.fb.group({
      email_: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.forgetPasswordFrm.controls;
  }

  forgotPassword(value) {
    this.io.changeMessage(this.forgetPasswordFrm.value.email_);
    if (!value.email_) {
      swal.fire({
        title: "Oops...",
        text: "Please input email.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!this.validateEmail(value.email_)) {
      swal.fire({
        title: "Oops...",
        text: "Please enter a valid email.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    let payload = {
      email: this.forgetPasswordFrm.value.email_,
    };

    this.io
      .apicall(payload, "restaurantowner/forgot-password-otp", "post")
      .then((data) => {
        if (this.io.data_.serverResponse.code == 200) {
          this.otpRecvied = data["result"].otp;
          localStorage.setItem("otp", this.otpRecvied);
          this.router.navigate(["/reset-password"]);
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  validateEmail(email_) {
    var re = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return re.test(String(email_).toLowerCase());
  }
}
