import { HttpClient } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "../../services/data-routing/data.service";
import { IoService } from "../../services/http/io.service";
import { PusherServiceService } from "../../services/http/pusher-service.service";

@Component({
  selector: "app-order-list",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.css"],
})
export class OrderListComponent implements OnInit, OnDestroy {
  totalPages: number;
  currentPage: number = 1;
  itemsPerPage: number = 25;
  orderList: any = [];
  orderStatusList: any;
  restaurantId: string = "";
  status: number | number[];
  selectedStatus: any = "";
  restaurantData: any;
  routerEventListener: any;
  paramObserver: Subscription;

  tabs: any = [];
  activeTab: number;

  constructor(
    private ioservice: IoService,
    private pusherService: PusherServiceService,
    private dataservice: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.restaurantData = JSON.parse(localStorage.getItem("restaurant"));
    this.restaurantId = this.restaurantData?.id;
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let paramPage = values.page;
      let paramTab = values.tab;
      let paramStatus = values.status || null;
      this.tabs = [
        { url: "", text: "New", status: [1] },
        { url: "", text: "On-going", status: [1, 4, 5, 6] },
        { url: "", text: "Completed", status: [7] },
        { url: "", text: "Canceled", status: [2, 3] },
        { url: "", text: "All", status: [1, 2, 3, 4, 5, 6, 7] },
      ]
      if (paramPage) {
        this.currentPage = +paramPage;
      } else {
        this.currentPage = 1;
      }
      if (paramTab) {
        this.activeTab = +paramTab;
      } else {
        this.activeTab = 0;
      }
      this.tabs[this.activeTab] = {
        ...this.tabs[this.activeTab],
        isActive: true,
      };
      if (paramStatus) {
        this.status = [+paramStatus];
        this.selectedStatus = +paramStatus;
      } else {
        this.status = this.tabs[this.activeTab]?.status;
      }
      this.handleParams(this.currentPage, this.activeTab);
      this.restaurantOrderStatusList();
      this.restaurantOrderList({
        page: this.currentPage,
        limit: this.itemsPerPage,
        restaurantId: this.restaurantId ? this.restaurantId : "",
        status: this.status ? this.status : "",
      });
    });
    this.pusherService.channel.bind(
      `new-order-initiated-${this.restaurantId}`,
      this.handlePusher
    );
    this.pusherService.channel.bind(
      `order-status-update-${this.restaurantId}`,
      this.handlePusher
    );
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
    this.pusherService.channel.unbind(
      `new-order-initiated-${this.restaurantId}`,
      this.handlePusher
    );
    this.pusherService.channel.unbind(
      `order-status-update-${this.restaurantId}`,
      this.handlePusher
    );
  }

  // RESTAURANT ORDER  STATUS LIST //
  restaurantOrderStatusList() {
    this.http.get<any>("./assets/data/orderStatus.json").subscribe((data) => {
      this.orderStatusList = data.orderStatus;
      this.orderStatusList = data.orderStatus.filter((item) =>
        this.tabs[this.activeTab]?.status?.includes(item.id)
      );
    });
  }

  handlePusher = (data) => {
    this.restaurantOrderList({
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantId ? this.restaurantId : "",
      status: this.status ? this.status : "",
    });
  };

  handleTabChange(index: number): void {
    this.selectedStatus = "";
    delete this.tabs[this.activeTab].isActive;
    this.tabs[index] = { ...this.tabs[index], isActive: true };
    this.activeTab = index;
    this.currentPage = 1;
    this.status = this.tabs[index]?.status;
    this.handleParams(this.currentPage, this.activeTab);
    this.restaurantOrderStatusList();
    this.restaurantOrderList({
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantId ? this.restaurantId : "",
      status: this.status ? this.status : "",
    });
  }

  handleParams(page, tab): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page,
        tab,
        status: this.selectedStatus ? this.selectedStatus : null,
      },
      queryParamsHandling: "merge",
    });
  }

  // RESTAURANT ORDER LIST //
  restaurantOrderList(payLoad: any): void {
    this.ioservice
      .apicall(payLoad, "orders/admin-list-of-orders", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.orderList = res["result"].orderList;
          this.totalPages = res["result"].totalRecords;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // PAGINATION //
  pageChanged(event) {
    this.handleParams(event, this.activeTab);
    if (this.currentPage >= 1 && this.currentPage < this.totalPages) {
      let payLoad = {
        page: this.currentPage,
        limit: this.itemsPerPage,
        restaurantId: this.restaurantId ? this.restaurantId : "",
        status: this.status ? this.status : "",
      };
      this.restaurantOrderList(payLoad);
    }
  }

  // SEARCH BY STATUS //
  searchByStatus(event) {
    this.status = [parseInt(event)];
    this.selectedStatus = event;
    this.orderList = [];
    this.currentPage = 1;
    this.handleParams(1, this.activeTab);
    let payLoad = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantId ? this.restaurantId : "",
      status: this.status ? this.status : "",
    };
    this.restaurantOrderList(payLoad);
  }
}
