import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-time-range",
  templateUrl: "./time-range.component.html",
  styleUrls: ["./time-range.component.css"],
})
export class TimeRangeComponent implements OnInit {
  @Input() from: any;
  @Input() to: any;
  @Output() fromChange = new EventEmitter<any>();
  @Output() toChange = new EventEmitter<any>();
  parsedTo:Object;
  parsedFrom:Object;

  constructor() {
  }

  ngOnInit(): void {
    this.parsedTo ={hour:+this.to?.split(":")[0],minute:+this.to?.split(":")[1]};
    this.parsedFrom ={hour:+this.from?.split(":")[0],minute:+this.from?.split(":")[1]};
  }



  handleChange(value, field): void {
    let parsedValue = value.hour.toString().padStart(2,"0")+":"+value.minute.toString().padStart(2,"0");
    if (field == "from") {
      this.fromChange.emit(parsedValue);
    } else {
      this.toChange.emit(parsedValue);
    }
  }
}
