import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent } from './auth.component';
import { LoginComponent, SignupComponent,ForgotPasswordComponent,VerifyEmailComponent } from './';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginGuard } from '../guards/login.guard';

const routes: Routes = [
  { 
    path: '', 
    component: AuthComponent,
    canActivate:[LoginGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate:[LoginGuard],
      },
      {
        path: 'signup',
        component: SignupComponent,
        canActivate:[LoginGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate:[LoginGuard],
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate:[LoginGuard],
      },
      {
        path: 'verifyEmail',
        component:VerifyEmailComponent,
        canActivate:[LoginGuard],
      }
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
