import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { DishRoutingModule } from "./dish-routing.module";
import { DishComponent } from "./dish.component";
import { AddDishComponent } from "./add-dish/add-dish.component";
import { EditDishComponent } from "./edit-dish/edit-dish.component";
import { DishListComponent } from "./dish-list/dish-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TagInputModule } from "ngx-chips";
import { NgxPaginationModule } from "ngx-pagination";
import { DecimalpointDirective } from "../validators/decimal.directives";
import { NumberDirective } from "../validators/numbers-only.directive";
import { doubleDecimalDirective } from "../validators/double-digit.directive";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
  declarations: [
    DishComponent,
    AddDishComponent,
    EditDishComponent,
    DishListComponent,
    DecimalpointDirective,
    NumberDirective,
    doubleDecimalDirective,
  ],
  imports: [
    CommonModule,
    DishRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    TagInputModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule.forRoot(),
    DragDropModule,
  ],
})
export class DishModule {}
