import { Component, Input, OnInit } from "@angular/core";
import { MenuCategory } from '../../../interfaces/menu-category.interface';

@Component({
  selector: "app-menu-category-list",
  templateUrl: "./menu-category-list.component.html",
  styleUrls: ["./menu-category-list.component.css"],
})
export class MenuCategoryListComponent implements OnInit {
  @Input() menuCategories: MenuCategory[]

  showDetails = false

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
