import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IoService } from "../../services/http/io.service";
import { HttpClient } from "@angular/common/http";
import { Moment } from "moment";
import { Subscription } from "rxjs";
@Component({
  selector: "app-restaurant-transaction-payout-view-details",
  templateUrl: "./restaurant-transaction-payout-view-details.component.html",
  styleUrls: ["./restaurant-transaction-payout-view-details.component.css"],
})
export class RestaurantTransactionPayoutViewDetailsComponent
  implements OnInit, OnDestroy
{
  transactionValue: any;
  restaurantPayoutId: any;
  page: any = 1;
  totalPages: any;
  itemsPerPage: number = 25;
  enqueryList: boolean = false;
  earningsResult: any;
  output: any = "dayOfYear";
  from: any;
  to: any;
  selected: { startDate: Moment; endDate: Moment };
  restaurantTransactionPayoutDetailsList: any = [];
  filteredTransactions: any = [];
  paramObserver: Subscription;

  constructor(
    private io: IoService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    let resObj = JSON.parse(localStorage.getItem("restaurant"));
    this.restaurantPayoutId = resObj?.id;
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPage = values.page;
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }
      this.allTransactionPayoutViewDetails();
      this.totalEarningsTransaction();
    });
    this.allTransactionPayoutViewDetails();
    this.totalEarningsTransaction();
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  change(e) {
    this.to = {
      day: this.selected.endDate.format("DD"),
      month: parseInt(this.selected.endDate.format("MM")) - 1,
      year: this.selected.endDate.format("YYYY"),
    };
    this.from = {
      day: this.selected.startDate.format("DD"),
      month: parseInt(this.selected.startDate.format("MM")) - 1,
      year: this.selected.startDate.format("YYYY"),
    };
    this.filterTransactionPayout();
  }
  changeFilterType(event) {
    this.output = event.target?.value;
    this.filterTransactionPayout();
  }
  totalEarningsTransaction() {
    let payload = {
      restaurantId: this.restaurantPayoutId,
    };

    this.io
      .apicall(payload, "restaurant/restaurant-view", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.earningsResult = res["result"][0];
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  allTransactionPayoutViewDetails() {
    this.restaurantTransactionPayoutDetailsList = [];
    let payload = {
      page: this.page,
      limit: this.itemsPerPage,
      restaurantId: this.restaurantPayoutId,
    };
    this.io
      .apicall(
        payload,
        "transaction/restaurant-payout-transaction-list",
        "post"
      )
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"].transactionList;
          //this.enqueryList = false;
          this.totalPages = res["result"].totalRecords;
          this.restaurantTransactionPayoutDetailsList = result;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  pageChanged(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: event },
      queryParamsHandling: "merge",
    });
    if (event >= 1) {
      this.allTransactionPayoutViewDetails();
    }
  }
  filterTransactionPayout() {
    console.log("selecet", this.selected);
    let payload = {
      restaurantId: this.restaurantPayoutId,
      from: this.from,
      to: this.to,
      output: this.output, // ['year', 'month', 'week', 'dayOfYear']
    };
    this.io
      .apicall(payload, "transaction/restaurant-transaction-list", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];

          this.filteredTransactions = result;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
}
