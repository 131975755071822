import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { DataService } from "../../../app/services/data-routing/data.service";
import { IoService } from "../../../app/services/http/io.service";
import { MapsAPILoader } from "@agm/core";
import { Location } from "@angular/common";
import swal from "sweetalert2";
import { environment } from "../../../environments/environment";
import { ValidatorsService } from "../../services/validators/validators.service";
import { sortDaysArray } from "../../utils/sortDaysArray";
declare var google: any;
@Component({
  selector: "app-profile-details",
  templateUrl: "./profile-details.component.html",
  styleUrls: ["./profile-details.component.css"],
})
export class ProfileDetailsComponent implements OnInit {
  dropdownSettings: IDropdownSettings;
  imageChangedEvent: any = "";
  imageChangedEvent1: any = "";
  croppedImage: any = "";
  croppedImage1: any = "";
  image: any;
  logoimage: any;
  canvasRotation = 0;
  transform: ImageTransform = {};
  showCropper = false;
  containWithinAspectRatio = false;
  closeResult = "";
  enableCropper = false;
  file: any;
  finalFile: any;
  logoFinalFile: any;
  resetPasswordFrm: FormGroup;
  editRestaurantFrm: FormGroup;
  firstParam: any;
  restaurantViewResult: any;
  locationName: any;
  lat: any;
  lng: any;
  @ViewChild("search") search: ElementRef;
  onlyCheck: any = "";
  restaurantCheck: any = "";
  countries: any;
  setTitle: any;
  logo: boolean = false;
  backgroundlogo: boolean = false;
  restaurantid: any;
  deliveryToggleBtn: boolean = false;
  pickupToggleBtn: boolean = false;
  maxAmount_: any;
  slavMaxDistance_: any;
  slavRangeForm_: any = 0;
  slavRangeTo_: any;
  slavDeliveryAmount_: any;
  deliverySlav: any = [];
  endDeliverySlavIndex = 0;
  workingHours: any[] = [];
  selectedCountry: any = "";
  countryCode: any = "";
  email: any = "";
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private route1: ActivatedRoute,
    private io: IoService,
    private router: Router,
    private ngZone: NgZone,
    public location: Location,
    private mapsAPILoader: MapsAPILoader,
    private dataservice: DataService,
    private http: HttpClient,
    private ValidatorsService: ValidatorsService
  ) {
    this.resetPasswordFrm = this.fb.group({
      oldPassword: [null, Validators.compose([Validators.required])],
      password_: [null, Validators.compose([Validators.required])],
      confirmpassword_: [null, Validators.compose([Validators.required])],
    });

    this.editRestaurantFrm = this.fb.group({
      restaurantName_: [null, Validators.compose([Validators.required])],
      contactno_: [
        null,
        Validators.compose([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "phone"
          ),
        ]),
      ],
      email_: [
        null,
        Validators.compose([Validators.required, Validators.email]),
      ],
      city_: [null, Validators.compose([Validators.required])],
      address_: [null, Validators.compose([Validators.required])],
      landmark_: [null, Validators.compose([Validators.required])],
      status_: [null, Validators.compose([Validators.required])],
      dishcount_: [null, Validators.compose([Validators.required])],
      restaurantdetails_: [null, Validators.compose([Validators.required])],
      latitude: [""],
      longitude: [""],
      addCommissionRate: ["", Validators.compose([Validators.required])],
      countryType: ["", Validators.compose([Validators.required])],
      workingHours: [null, [ValidatorsService.validateWorkingHours]],
      smsNotification: [false],
      smsPhone_: [null],
    });
    this.setCustomValidators();
  }

  setCustomValidators(): void {
    const smsNotificationControl =
      this.editRestaurantFrm.get("smsNotification");
    const smsPhoneControl = this.editRestaurantFrm.get("smsPhone_");
    smsNotificationControl.valueChanges.subscribe((value) => {
      if (value) {
        smsPhoneControl.setValidators([
          Validators.required,
          this.ValidatorsService.validatePhoneNumber(
            () => this.countryCode,
            "mobile"
          ),
        ]);
      } else {
        smsPhoneControl.setValidators(null);
      }
      smsPhoneControl.updateValueAndValidity();
    });
  }

  ngOnInit(): void {
    this.getLoc();
    this.loadDropDown();
    let resObj = JSON.parse(localStorage.getItem("restaurant"));
    this.restaurantid = resObj?.id;
    this.email = resObj?.email;
    // this.firstParam = this.route1.snapshot.queryParamMap.get("id")
    this.restaurantView();

    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.search.nativeElement,
        { types: [] }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          const place: typeof google.maps.places.PlaceResult =
            autocomplete.getPlace();
          if (!place.place_id) {
            window.alert("Please select an option from the dropdown list.");
            return;
          }
          this.locationName = place.name;
          // verify result //
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom //
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.editRestaurantFrm.patchValue({
            address_: this.locationName,
            latitude: this.lat,
            longitude: this.lng,
          });
          //this.zoom = 12;
        });
      });
    });
  }
  updateFormWorkingHours(newHours) {
    this.editRestaurantFrm.patchValue({
      workingHours: newHours,
    });
  }

  handleHoursChange(value, index, field): void {
    let current = { ...this.workingHours[index] };
    current[Object.keys(current)[0]] = {
      ...current[Object.keys(current)[0]],
      [field]: value,
    };
    this.workingHours[index] = current;
    this.updateFormWorkingHours(this.workingHours);
  }

  getDayFieldValue(item, field): string {
    if (field === "day") {
      return Object.keys(item.value)[0];
    } else {
      return null;
    }
  }

  isDayOpen(item) {
    return item.value[Object.keys(item.value)[0]] !== null;
  }

  handleDayOpenClose(index) {
    let current = { ...this.workingHours[index] };
    if (current[Object.keys(current)[0]] == null) {
      current[Object.keys(current)[0]] = { from: "09:00", to: "22:00" };
    } else {
      current[Object.keys(current)[0]] = null;
    }
    this.workingHours[index] = current;
    this.updateFormWorkingHours(this.workingHours);
  }

  getLoc() {
    this.http.get<any>("./assets/data/country.json").subscribe((data) => {
      this.countries = data.countries;
      if (this.countries?.length == 1) {
        this.selectedCountry = this.countries[0].name;
        this.countryCode = this.countries[0].code;
        this.editRestaurantFrm.patchValue({
          countryType: this.selectedCountry,
        });
      }
    });
  }

  countryType(event) {
    let selectedCountry = event.target.value;
    let index = this.countries.filter((item) => item.name === selectedCountry);
    this.countryCode = index[0].code;
  }

  loadDropDown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
  }

  open(content, status) {
    if (status == "background") {
      this.setTitle = "Restaurant Background image";
      this.logo = false;
      this.backgroundlogo = true;
    }
    if (status == "logo") {
      this.setTitle = "Restaurant Logo image";
      this.backgroundlogo = false;
      this.logo = true;
    }
    this.imageChangedEvent = "";
    this.imageChangedEvent1 = "";
    this.croppedImage = "";
    this.croppedImage1 = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  restaurantView() {
    let payload = {
      restaurantId: this.restaurantid,
    };
    this.io
      .apicall(payload, "restaurant/restaurant-view", "post")
      .then((res) => {
        if (res["serverResponse"].code === 200) {
          this.restaurantViewResult = res["result"][0];
          let storeResult = res["result"][0];
          let selectedCountry = this.restaurantViewResult.country;
          let index = this.countries.filter(
            (item) => item.name === selectedCountry
          );
          if (index.length > 0) {
            this.countryCode = index[0].code;
          }
          this.slavMaxDistance_ = storeResult.maxDistCoveredByRestaurant;
          this.deliveryToggleBtn = storeResult.isDeliveryAvaliable;
          this.pickupToggleBtn = storeResult.isPickupAvaliable;
          this.restaurantCheck = this.restaurantViewResult?.IsNew;
          this.onlyCheck = this.restaurantViewResult?.isOnly;
          this.image = this.restaurantViewResult.imageUrl;
          this.logoimage = this.restaurantViewResult.restaurantLogo;
          if (
            !this.restaurantViewResult?.workingHours ||
            Object.keys(this.restaurantViewResult.workingHours)?.length == 0
          ) {
            this.workingHours = [
              { monday: null },
              { tuesday: null },
              { wednesday: null },
              { thursday: null },
              { friday: null },
              { saturday: null },
              { sunday: null },
            ];
          } else {
            this.workingHours = Object.entries(
              this.restaurantViewResult.workingHours
            ).map((item) => {
              let arr: Array<any> = [];
              let obj = {};
              arr = arr.concat(item[1]);
              if (arr.length > 0) {
                obj = { ...arr[0] };
              }
              return { [item[0]]: arr.length == 0 ? null : obj };
            });
          }
          this.workingHours = sortDaysArray(this.workingHours);
          this.editRestaurantFrm.patchValue({
            restaurantName_: this.restaurantViewResult.restaurantName,
            contactno_: this.restaurantViewResult.contactNo,
            email_: this.restaurantViewResult.email,
            city_: this.restaurantViewResult.city,
            status_: this.restaurantViewResult?.isActive
              ? "Active"
              : "Inactive",
            address_: this.restaurantViewResult.address,
            landmark_: this.restaurantViewResult.landmark,
            dishcount_: this.restaurantViewResult.numberOfDish,
            restaurantdetails_: this.restaurantViewResult.restaurantDetails,
            latitude: this.restaurantViewResult.latitude,
            longitude: this.restaurantViewResult.longitude,
            addCommissionRate: this.restaurantViewResult.commissionRate,
            countryType:
              this.countries?.length == 1
                ? this.selectedCountry
                : this.restaurantViewResult.country,
            workingHours: this.workingHours,
            countryCode: this.countryCode,
            smsNotification: this.restaurantViewResult?.notifyOnNewOrder,
            smsPhone_: this.restaurantViewResult?.notificationNumber || null,
          });
          this.editRestaurantFrm.markAllAsTouched();

          this.deliverySlav = [];
          for (let slav of storeResult.deliverySlav) {
            this.deliverySlav.push({
              rangeFrom: slav.rangeFrom,
              rangeTo: slav.rangeTo,
              deliveryAmount: slav.deliveryAmount,
            });
          }
          this.endDeliverySlavIndex = this.deliverySlav.length - 1;

          if (this.deliverySlav.length > 0) {
            this.slavRangeForm_ =
              this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  viewDishList() {
    let Obj = {
      restaurantId: this.firstParam,
      resturantName: this.restaurantViewResult.restaurantName,
    };
    this.dataservice._setObj(Obj);
    this.router.navigate(["/dish/dish-list"]);
  }

  sendValue() {
    let keyname = {
      restaurantName: this.restaurantViewResult.restaurantName,
      restaurantId: this.firstParam,
    };
    this.dataservice._setObj(keyname);
    this.router.navigate(["/dish/add-dish"]);
  }

  // toggleCheckIsOnly(event) {
  //   this.onlyCheck = event.target.checked;
  // }
  // toggleCheckRestaurant(event) {
  //   this.restaurantCheck = event.target.checked;
  // }

  editRestaurant(value) {
    if (!this.editRestaurantFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all the requried fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editRestaurantFrm.markAllAsTouched();
      this.editRestaurantFrm.updateValueAndValidity();
      return;
    }
    let hoursParsed = {};
    this.workingHours.forEach((item) => {
      hoursParsed[Object.keys(item)[0]] =
        item[Object.keys(item)[0]] == null ? [] : [item[Object.keys(item)[0]]];
    });
    let payload = {
      restaurantId: this.restaurantid,
      restaurantName: value.restaurantName_,
      contactNo: value.contactno_.toString(),
      email: value.email_,
      city: value.city_,
      country: value.countryType,
      countryCode: this.countryCode,
      address: value.address_,
      landmark: value.landmark_,
      latitude: value.latitude,
      longitude: value.longitude,
      imageUrl: this.image,
      restaurantLogo: this.logoimage,
      IsNew: true,
      isOnly: false,
      restaurantDetails: value.restaurantdetails_,
      // commissionRate: value.addCommissionRate,
      workingHours: hoursParsed,
      notifyOnNewOrder: value.smsNotification,
      notificationNumber: value.smsNotification ? value.smsPhone_ : null,
    };
    this.io
      .apicall(payload, "restaurant/edit-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          //this.router.navigate(["/resturant/restaurant-list"]);
          //this.editRestaurantFrm.reset();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent.target.value;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  fileChangeEvent1(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.logoimage = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent1 = event;
      this.logoimage = this.imageChangedEvent1;
    }
    //this.fileToUpload = event.target.files[0];
  }
  imageCropped1(event: ImageCroppedEvent) {
    this.croppedImage1 = event.base64;
    this.logoFinalFile = base64ToFile(this.croppedImage1);
    this.enableCropper = true;
  }
  imageLoaded1(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady1() {
    // cropper ready
  }
  loadImageFailed1() {
    // show message
  }

  saveImage() {
    const fd = new FormData();
    fd.append("file", this.finalFile);
    this.io
      .apicall(fd, "uploads/restaurant-img-upload", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.image = res.result[0].fileUrl;
          this.modalService.dismissAll();
        }
      });
  }

  logoImage() {
    const fd = new FormData();
    fd.append("file", this.logoFinalFile);
    this.io
      .apicall(fd, "uploads/restaurant-img-upload", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.logoimage = res.result[0].fileUrl;
          this.modalService.dismissAll();
        }
      });
  }

  closeModal() {
    this.modalService.dismissAll();
    // this.image = "";
    this.enableCropper = false;
  }
  save() {
    this.modalService.dismissAll();
  }

  // activeInactive(obj) {
  //   let payLoad;
  //   if (obj.isActive == true) {
  //     payLoad = {
  //       restaurantId: obj._id,
  //       isActive: false,
  //     };
  //   } else {
  //     payLoad = {
  //       restaurantId: obj._id,
  //       isActive: true,
  //     };
  //   }

  //   this.io
  //     .apicall(payLoad, "restaurant/change-restaurant-status", "post")
  //     .then((res: any) => {
  //       if (res["serverResponse"].code === 200) {
  //         swal.fire({
  //           title: "Success",
  //           text: res["serverResponse"].message,
  //           icon: "success",
  //           confirmButtonColor: "#442DFF;",
  //           confirmButtonText: "ok",
  //         });
  //         this.restaurantView();
  //       }
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });
  // }

  //SETTINGS REALTED WORK //

  // TOGGLE ON OFF FOR DELIVERY //
  toggleForDelivery(event) {
    this.deliveryToggleBtn = event.target.checked;
    let payload = {
      restaurantId: this.restaurantid,
      isDeliveryAvaliable: this.deliveryToggleBtn,
      isPickupAvaliable: this.pickupToggleBtn,
    };
    this.io
      .apicall(payload, "restaurant/set-delivery-or-pickup-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // TOGGLE ON OFF FOR PICKUP //
  toggleForPickup(event) {
    this.pickupToggleBtn = event.target.checked;
    let payload = {
      restaurantId: this.restaurantid,
      isDeliveryAvaliable: this.deliveryToggleBtn,
      isPickupAvaliable: this.pickupToggleBtn,
    };
    this.io
      .apicall(payload, "restaurant/set-delivery-or-pickup-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // ADD SLAV RANGE //
  addRangeSlav() {
    if (this.slavRangeForm_ > this.slavRangeTo_) {
      alert("RangeTo must be greater than RangeFrom");
      return false;
    }
    if (this.slavMaxDistance_ < this.slavRangeTo_) {
      alert("Your given delivery range is exceeded");
      return false;
    }
    this.deliverySlav.push({
      rangeFrom: this.slavRangeForm_,
      rangeTo: this.slavRangeTo_,
      deliveryAmount: this.slavDeliveryAmount_,
    });
    if (this.deliverySlav.length > 0) {
      this.slavRangeForm_ =
        this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
    }
    this.endDeliverySlavIndex = this.deliverySlav.length - 1;
    // this.slavRangeForm_ = "";
    this.slavRangeTo_ = "";
    this.slavDeliveryAmount_ = "";
  }

  // DELETE SLAV ITEM //
  deleteSlavRange(id: number) {
    this.deliverySlav.splice(
      this.deliverySlav.findIndex((abc) => abc.id === id),
      1
    );
    this.endDeliverySlavIndex = this.deliverySlav.length - 1;
    if (this.deliverySlav.length > 0) {
      this.slavRangeForm_ =
        this.deliverySlav[this.deliverySlav.length - 1].rangeTo + 1;
    } else if (this.deliverySlav.length == 0) {
      this.slavRangeForm_ = 0;
    }
  }

  // SLAV MAX DISTANCE //
  maxDistanceSave() {
    let payload = {
      restaurantId: this.restaurantid,
      maxDistCoveredByRestaurant: this.slavMaxDistance_,
    };
    this.io
      .apicall(payload, "restaurant/set-max-dist-covered-by-restaurant", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          // swal.fire({
          //   title: "Success",
          //   text: res["serverResponse"].message,
          //   icon: "success",
          //   confirmButtonColor: "#442DFF;",
          //   confirmButtonText: "ok",
          // });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  // ADD SLAV LIST //
  addSlavList() {
    if (
      this.slavMaxDistance_ !=
      this.deliverySlav[this.deliverySlav.length - 1].rangeTo
    ) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you covered all ranges in your specified radius",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return false;
    } else {
      this.maxDistanceSave();
      let payload = {
        restaurantId: this.restaurantid,
        deliverySlav: this.deliverySlav,
      };
      this.io
        .apicall(payload, "restaurant/add-delivery-slav", "post")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            swal.fire({
              title: "Success",
              text: res["serverResponse"].message,
              icon: "success",
              confirmButtonColor: "#442DFF;",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          swal.fire({
            title: "Oops...",
            text: this.io.data_.serverResponse.message,
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        });
    }
  }
  get f() {
    return this.resetPasswordFrm.controls;
  }

  restPassWord(value) {
    if (!value.oldPassword) {
      swal.fire({
        title: "Oops...",
        text: "Please input old password.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    if (!value.password_) {
      swal.fire({
        title: "Oops...",
        text: "Please input new password.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!value.confirmpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Please confirm password.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    if (value.password_ !== value.confirmpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Passwords do not match.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    let payload = {
      email: this.email,
      oldPassword: this.resetPasswordFrm.value.oldPassword,
      newPassword: this.resetPasswordFrm.value.password_,
    };
    this.io
      .apicall(payload, "restaurantowner/change-password", "post")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }
}
