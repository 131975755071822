import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { TransactionModule } from './transaction/transaction.module';
import { AuthModule } from './auth/auth.module';
import { DishModule } from './dish/dish.module';
import {BranchModule } from './branch/branch.module';
import { OrderModule } from './order/order.module';
import { ProfileModule } from './profile/profile.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { HttpConfigInterceptor } from './services/intersepters/http-config.intercept';
import { NgxUiLoaderModule,  NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION, NgxUiLoaderRouterModule,  } from "ngx-ui-loader";
import { ModalModule } from './modal/modal.module';
import { MenuModule } from './menu/menu.module';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#232526',
  bgsOpacity: 0.9,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.squareLoader,
  blur: 5,
  delay: 0,
  fgsColor: '#B2D571',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 80,
  fgsType: SPINNER.rotatingPlane,
  gap: 24,
  pbColor: '#fdd000',
  pbDirection: PB_DIRECTION.leftToRight,
  pbThickness: 3,
  hasProgressBar: true
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    NgbModule,
    AuthModule,
    DishModule,
    BranchModule,
    MenuModule,
    ToastrModule.forRoot(),
    ModalModule,
    AgmCoreModule.forRoot({
      apiKey: environment?.googleMapKey,
      libraries: ['places']
    }),
    TransactionModule,
    OrderModule,
    ProfileModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
