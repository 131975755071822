import { Component, Input, OnInit } from "@angular/core";
import { MenuProduct } from '../../../interfaces/menu-product.interface';

@Component({
  selector: "app-menu-product-list",
  templateUrl: "./menu-product-list.component.html",
  styleUrls: ["./menu-product-list.component.css"],
})
export class MenuProductListComponent implements OnInit {
  @Input() menuProducts: MenuProduct[]

  showDetails = false

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
