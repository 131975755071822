import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IoService } from "../../services/http/io.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  base64ToFile,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import swal from "sweetalert2";
import { DataService } from "../../services/data-routing/data.service";
import { forkJoin, Subscription } from "rxjs";
import { DietaryPreferencesService } from "./../../services/dietaryPreferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tagManagement/tag-management.service";

@Component({
  selector: "app-edit-dish",
  templateUrl: "./edit-dish.component.html",
  styleUrls: ["./edit-dish.component.css"],
})
export class EditDishComponent implements OnInit, OnDestroy {
  selectedItems2 = [];
  selectedItems3 = [];
  selectedItems4 = [];
  dropdownSettings: IDropdownSettings;
  editDishFrm: FormGroup;
  croppedImage: any = "";
  image: any = "";
  file: any;
  closeResult = "";
  imageChangedEvent: any = "";
  finalFile: any = "";
  enableCropper = false;
  showCropper = false;
  transform: ImageTransform = {};
  allHeadList: any = [];
  foodTypeHeadId: any = "";
  foodTypeList: any = [];
  mealTimeHeadId: any = "";
  mealTimeList: any = [];
  cuisineHeadId: any = "";
  cuisineList: any = [];
  mealTimeSelectUnselect = [];
  cuisineSelectUnselect = [];
  foodTypeSelectUnselect = [];
  allListedItem = [];
  dishId: string;
  isNewDishCheck: any;
  dishDetails: any;
  resturantName: any = "";
  discountArray: any = [];
  finalAddTagArr: any = [];
  paste: boolean = true;
  selectedDiscountType: any = "flat";
  discount_: number = 0;
  paramObserver: Subscription;
  dietaryPreferences: any = [];
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private io: IoService,
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private cd: ChangeDetectorRef,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.params.subscribe(({ id }) => {
      if (id) {
        this.dishId = id;
        this.loadDropDown();
        this.readyeditDishFrm();
        this.loadDiscountType();
        this.fetchAllHead();
      } else {
        this.router.navigate(["/dish/dish-list"]);
      }
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  loadDiscountType() {
    return this.discountArray.push(
      { id: 0, discountType: "flat" },
      { id: 1, discountType: "percentage" }
    );
  }

  discountTypeOnChange() {
    this.discount_ = this.editDishFrm.value.discount;
    let price = this.editDishFrm.value.price;
    let discount = this.editDishFrm.value.discount;
    let netPrice: any = 0;
    let currentDiscountType = this.editDishFrm.value.discountType
      ? this.editDishFrm.value.discountType
      : this.selectedDiscountType;
    if (currentDiscountType === "flat") {
      netPrice = (price - discount).toFixed(2);
      this.editDishFrm.get("netprice").setValue(netPrice);
    } else if (currentDiscountType === "percentage") {
      netPrice = (price - (price * discount) / 100).toFixed(2);
      this.editDishFrm.get("netprice").setValue(netPrice);
    } else {
      netPrice = price;
    }
  }

  open(content) {
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  loadDropDown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "tagId",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 2,
      allowSearchFilter: true,
    };
  }

  readyeditDishFrm() {
    this.editDishFrm = this.fb.group({
      dishname_: ["", Validators.compose([Validators.required])],
      carbs_: ["", Validators.compose([Validators.required])],
      protien_: ["", Validators.compose([Validators.required])],
      fat_: ["", Validators.compose([Validators.required])],
      totalcal_: ["", Validators.compose([Validators.required])],
      price: ["", Validators.compose([Validators.required])],
      newdish_: [false, Validators.compose([Validators.required])],
      dishdetails_: ["", Validators.compose([Validators.required])],
      discount: ["", Validators.compose([Validators.required])],
      discountType: [""],
      netprice: [
        "",
        Validators.compose([Validators.required, Validators.min(2)]),
      ],
    });
  }

  setDiscountTypeValidator(): void {
    const discountControl = this.editDishFrm.get("discount");
    const discountTypeControl = this.editDishFrm.get("discountType");

    discountControl.valueChanges.subscribe((value) => {
      if (value !== "" && value > 0) {
        discountTypeControl.setValidators([Validators.required]);
      } else {
        discountTypeControl.setValidators(null);
      }
    });
  }

  loadDishDetails() {
    let payload = {
      dishId: this.dishId,
    };
    this.io
      .apicall(payload, "dish/dish-view", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          let storeResult = res["result"][0];
          this.dishDetails = res["result"][0];
          this.isNewDishCheck = res["result"][0].IsNew;
          this.image = res["result"][0].discImage;
          this.editDishFrm.get("dishname_").setValue(storeResult.dishName);
          //this.recipeName =  storeResult.recipeName;
          this.editDishFrm.get("carbs_").setValue(storeResult.carbs);
          this.editDishFrm.get("protien_").setValue(storeResult.protein);
          this.editDishFrm.get("fat_").setValue(storeResult.fat);
          this.editDishFrm.get("totalcal_").setValue(storeResult.totalCalories);
          this.editDishFrm.get("price").setValue(storeResult.price);
          this.editDishFrm.get("newdish_").setValue(storeResult.IsNew);
          this.editDishFrm.get("discount").setValue(storeResult.discount);
          this.editDishFrm
            .get("netprice")
            .setValue(
              storeResult?.netPrice
                ? Number(storeResult.netPrice).toFixed(2)
                : null
            );
          this.editDishFrm
            .get("discountType")
            .setValue(storeResult.discountType);
          this.editDishFrm
            .get("dishdetails_")
            .setValue(storeResult.dishdetails);

          this.selectedItems2 = storeResult.headTagInfo[2].tags;
          this.selectedItems3 = storeResult.headTagInfo[3].tags;
          this.selectedItems4 = storeResult.headTagInfo[4].tags;

          for (const tags of storeResult.headTagInfo[2].tags) {
            this.mealTimeSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }
          for (const tags of storeResult.headTagInfo[3].tags) {
            this.cuisineSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          for (const tags of storeResult.headTagInfo[4].tags) {
            this.foodTypeSelectUnselect.push({
              tagId: tags.tagId,
              tagName: tags.tagName,
            });
          }

          this.allListedItem = [...storeResult?.nutritionalInfo];
          this.dietaryPreferences.forEach((item) => {
            if (storeResult?.dietaryPreferences[item?.id]) {
              let selection = storeResult?.dietaryPreferences[item?.id].map(
                (mapItem) => {
                  let searchRes = item.options?.find(
                    (searchItem) => searchItem.id == mapItem
                  );
                  if (searchRes) {
                    return { id: searchRes?.id, name: searchRes.name };
                  }
                }
              );
              this.editDishFrm.patchValue({ [item.name]: selection });
            }
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  //================================== 'section for dropdown' ======================================================================//

  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        this.allHeadList = tagRes["result"];
        for (var i = 0; i < this.allHeadList.length; i++) {
          if (this.allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(this.allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name=='Macronutrient Ratio'),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.editDishFrm.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }

  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
      if (item.selectionType == "exclude") {
        this.finalAddTagArr = this.finalAddTagArr.concat(
          item.options.map((mapItem) => {
            return {
              ...mapItem,
              tagName: mapItem.name + " Free",
              tagId: mapItem.id,
            };
          })
        );
        this.handleExcludePreference(item.name, index);
      }
    });

    this.loadDishDetails();
  }

  handleExcludePreference(controlName: string, index: number): void {
    const field_control = this.editDishFrm.get(controlName);
    field_control.valueChanges.subscribe((value) => {
      this.allListedItem = this.allListedItem.filter(
        (filteredItem) =>
          !value.find((searchItem) => searchItem?.id == filteredItem.tagId)
      );
      let options = [...this.dietaryPreferences[index].options];
      this.finalAddTagArr = options
        .filter(
          (filteredItem) =>
            !value.find((searchItem) => searchItem.id == filteredItem.id)
        )
        .map((item) => {
          return { ...item, tagName: item.name + " Free", tagId: item.id };
        });
    });
  }

  async headTagFoodType(Obj) {
    this.foodTypeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr1 = [];
    for (let tags of value) {
      myArr1.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.foodTypeList = myArr1;
  }

  async headTagMealTime(Obj) {
    this.mealTimeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr3 = [];
    for (let tags of value) {
      myArr3.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.mealTimeList = myArr3;
  }

  async headTagCusine(Obj) {
    this.cuisineHeadId = Obj;
    let value = await this.giveTagList(Obj);
    let myArr4 = [];
    for (let tags of value) {
      myArr4.push({ tagId: tags.id, tagName: tags.tagName });
    }
    this.cuisineList = myArr4;
  }

  async giveTagList(Obj): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: Obj,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "post")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //=============================================' diet select dropdown ' ================================================================//

  //=============================================' start of meal time '==================================================================//
  onItemSelect2(item: any) {
    //meal time single item Select
    this.mealTimeSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll2(item: any) {
    //meal time all item Select
    this.mealTimeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.mealTimeSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect2(event: any) {
    //meal time single item deSelect

    this.mealTimeSelectUnselect.splice(
      this.mealTimeSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll2(item: any) {
    //meal time all item deSelect

    this.mealTimeSelectUnselect = [];
  }
  //=============================================' end of meal time '====================================================================//

  //=============================================' start of cusine ' =====================================================================//
  onItemSelect3(item: any) {
    //cuisine single item Select
    this.cuisineSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll3(item: any) {
    //cuisine all item Select
    this.cuisineSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.cuisineSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect3(event: any) {
    //cuisine single item deSelect
    this.cuisineSelectUnselect.splice(
      this.cuisineSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll3(item: any) {
    //cuisine all item deSelect
    this.cuisineSelectUnselect = [];
  }
  //==============================================' end of cusine '========================================================================//

  //==============================================' start of food type ' ================================================================//
  onItemSelect4(item: any) {
    //food type single item Select
    this.foodTypeSelectUnselect.push({
      tagId: item.tagId,
      tagName: item.tagName,
    });
  }

  onSelectAll4(item: any) {
    //food type all item Select
    this.foodTypeSelectUnselect = [];
    for (let i = 0; i < item.length; i++) {
      this.foodTypeSelectUnselect.push({
        tagId: item[i].tagId,
        tagName: item[i].tagName,
      });
    }
  }

  onItemDeSelect4(event: any) {
    this.foodTypeSelectUnselect.splice(
      this.foodTypeSelectUnselect.findIndex(
        (tier) => tier.tagId === event.tagId
      ),
      1
    );
  }

  onDeSelectAll4(item: any) {
    this.foodTypeSelectUnselect = [];
  }
  //==============================================' end of food type '===================================================================//

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent.target.value;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }
  imageLoaded(image: HTMLImageElement) {
    this.showCropper = true;
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  editDish(Obj) {
    if (!this.editDishFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.editDishFrm.markAllAsTouched();
      return;
    }
    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.editDishFrm
        .get(item.name)
        .value.map((preference) => preference.id);
    });
    let payload = {
      dishId: this.dishId,
      restaurantId: this.dishDetails.restaurantId,
      dishName: Obj.dishname_,
      // prepTime: parseInt(Obj.preptime_),
      discImage: this.image,
      carbs: Obj.carbs_,
      fat: Obj.fat_,
      protein: Obj.protien_,
      totalCalories: Obj.totalcal_,
      nutritionalInfo: this.allListedItem,
      IsNew: this.isNewDishCheck,
      dishdetails: Obj.dishdetails_,
      price: Obj.price,
      discountType: Obj.discountType ? Obj.discountType : "flat",
      discount: Obj.discount ? Obj.discount : 0,
      netPrice: Obj.netprice,
      headTagInfo: [
        {
          headId: this.mealTimeHeadId,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.cuisineHeadId,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.foodTypeHeadId,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };
    this.io
      .apicall(payload, "dish/edit-dish", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/dish/dish-list"]);
          //this.loadDishDetails();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  saveImage() {
    const fd = new FormData();
    fd.append("file", this.finalFile);
    this.io.apicall(fd, "uploads/dish-img-upload", "post").then((res: any) => {
      if (res["serverResponse"].code === 200) {
        this.image = res["result"][0].fileUrl;
        this.modalService.dismissAll();
      }
    });
  }

  publish() {
    let payload = {
      dishId: this.dishId,
    };
    this.io
      .apicall(payload, "dish/dish-publish-unpublish", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.loadDishDetails();
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  toggleActive(obj) {
    let payload;
    if (obj.isActive == true) {
      payload = {
        dishId: this.dishId,
        isActive: false,
      };
    } else {
      payload = {
        dishId: this.dishId,
        isActive: true,
      };
    }

    this.io
      .apicall(payload, "dish/change-dish-status", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.dishDetails.isActive = payload.isActive;
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }

  checkedDish(event) {
    this.isNewDishCheck = event.target.checked;
  }
}
