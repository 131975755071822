import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { MenuRoutingModule } from "./menu-routing.module";
import { MenuComponent } from "./menu.component";
import { MenuListComponent } from "./menu-list/menu-list.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { TimeRangeModule } from "./../time-range/time-range.module";
import { TabNavigatorModule } from "./../tab-navigator/tab-navigator.module";
import { MenuItemComponent } from './menu-list/components/menu-item/menu-item.component';
import { MenuAvailabilityComponent } from './menu-list/components/menu-availability/menu-availability.component';
import { MenuAvailabilityListComponent } from './menu-list/components/menu-availability-list/menu-availability-list.component';
import { MenuCategoryListComponent } from './menu-list/components/menu-category-list/menu-category-list.component';
import { MenuCategoryComponent } from './menu-list/components/menu-category/menu-category.component';
import { MenuProductListComponent } from './menu-list/components/menu-product-list/menu-product-list.component';
import { MenuProductComponent } from './menu-list/components/menu-product/menu-product.component';
import { SubProductListComponent } from './menu-list/components/sub-product-list/sub-product-list.component';
import { SubProductComponent } from './menu-list/components/sub-product/sub-product.component';

@NgModule({
  declarations: [
    MenuComponent,
    MenuListComponent,
    MenuItemComponent,
    MenuAvailabilityComponent,
    MenuAvailabilityListComponent,
    MenuCategoryListComponent,
    MenuCategoryComponent,
    MenuProductListComponent,
    MenuProductComponent,
    SubProductListComponent,
    SubProductComponent,
  ],
  imports: [
    CommonModule,
    MenuRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    TimeRangeModule,
    TabNavigatorModule,
  ],
  providers: [],
})
export class MenuModule { }
