import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ProfileRoutingModule } from "./profile-routing.module";
import { ProfileComponent } from "./profile.component";
import { ProfileDetailsComponent } from "./profile-details/profile-details.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimeRangeModule } from "./../time-range/time-range.module";
import { TimeRangeComponent } from "./../time-range/time-range.component";
import { NgbTimepickerModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [ProfileComponent, ProfileDetailsComponent, TimeRangeComponent],
  imports: [
    CommonModule,
    ProfileRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    TimeRangeModule,
    NgbTimepickerModule
  ],
})
export class ProfileModule {}
