import { DataService } from "../../services/data-routing/data.service";
import { environment } from "../../../environments/environment";
import { IoService } from "../../services/http/io.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { DishService } from "../../services/dish/dish.service";
import {
  FormControl,
  FormArray,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import swal from "sweetalert2";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-dish-list",
  templateUrl: "./dish-list.component.html",
  styleUrls: ["./dish-list.component.css"],
})
export class DishListComponent implements OnInit {
  page: number = 1;
  total: number;
  editMode: boolean = false;
  limitPerPage: number = 10;
  dishAllList: any = [];
  dishes: any = [];
  resturantListForDropdown: any = [];
  resturantName: any = "";
  enableDisableForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private setData: DataService,
    private ioservice: IoService,
    private router: Router,
    private DishService: DishService,
    private loader: NgxUiLoaderService
  ) {
    this.enableDisableForm = this.fb.group({
      dishes: new FormArray([]),
    });
  }

  ngOnInit(): void {
    this.resturantName = JSON.parse(localStorage.getItem("restaurant"));
    this.allDishList();
    this.resturantList();
  }
  isAllSelected(val) {
    return false;
  }
  checkAvailability(val) {
    let arr = this.enableDisableForm.get("dishes").value;
    return arr.some((arrVal) => val === arrVal);
  }
  onCheckUncheckChange(event) {
    const formArray: FormArray = this.enableDisableForm.get(
      "dishes"
    ) as FormArray;
    let ids = [];

    if (event.target.checked) {
      this.dishAllList.forEach((item) => {
        formArray.push(new FormControl(item.id));
      });
      this.dishes = formArray.length;
      // this.selectAll = true;
    } else {
      let _arr1 = this.dishAllList.map((a) => a.id);

      let _arr2 = this.enableDisableForm.get("dishes").value;

      // _arr1 = _arr1.filter(function(val) {
      //   return _arr2.indexOf(val) == -1;
      // });
      // Or, with the availability of ES6:

      _arr1 = _arr2.filter((val) => !_arr1.includes(val));

      formArray.clear();
      _arr1.forEach((item) => {
        formArray.push(new FormControl(item));
      });

      this.dishes = formArray.length;
    }
  }
  onCheckChange(event) {
    const formArray: FormArray = this.enableDisableForm.get(
      "dishes"
    ) as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    } else {
      /* unselected */
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }
    this.dishes = formArray.length;
  }
  activeInactive(activeInActive) {
    let payLoad = {};
    payLoad = {
      dishIds: this.enableDisableForm.get("dishes").value,
      isActive: activeInActive,
    };
    this.ioservice
      .apicall(payLoad, "dish/change-dish-status-bulk", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const formArray: FormArray = this.enableDisableForm.get(
            "dishes"
          ) as FormArray;
          formArray.clear();
          this.dishes = [];
          this.allDishList();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  onDrop(event: CdkDragDrop<any>) {
    moveItemInArray(this.dishAllList, event.previousIndex, event.currentIndex);
  }

  allDishList() {
    let payLoad = {};
    payLoad = {
      restaurantId: this.resturantName?.id,
      orderName: "rank",
      orderType: 1,
    };
    this.loader.start();
    this.ioservice
      .apicall(payLoad, "dish/all-dish-list", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.dishAllList = result.dishList;
        }
        this.loader.stop();
      })
      .catch((err) => {
        this.loader.stop();
        throw err;
      });
  }
  arrayCompare() {
    let _arr1 = this.dishAllList.map((a) => a.id);

    let _arr2 = this.enableDisableForm.get("dishes").value;
    _arr1 = _arr1.filter((val) => _arr2.includes(val));
    if (_arr1.length < this.dishAllList.length) {
      return false;
    }
    return true;
  }
  resturantList() {
    this.loader.start();
    this.ioservice
      .apicall({}, "restaurant/get-restaurant-list-for-dropdown", "POST")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          this.resturantListForDropdown = res["result"];
        }
        this.loader.start();
      })
      .catch((err) => {
        this.loader.start();
        throw err;
      });
  }
  onEditClick(): void {
    this.editMode = true;
  }

  onEditSave(): void {
    this.loader.start();
    let order = this.dishAllList.map((item) => item.id);
    this.editMode = false;
    this.DishService.changeDishesOrder(order, this.resturantName?.id).subscribe(
      () => {
        this.loader.stop();
        swal.fire({
          title: "Success",
          text: "Order changed successfully",
          icon: "success",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      },
      () => {
        swal.fire({
          title: "Oops...",
          text: "Something went wrong!",
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
        this.loader.stop();
      }
    );
  }
}
