import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import swal from "sweetalert2";
import * as $ from "jquery";
import { IoService } from "../../services/http/io.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordFrm: FormGroup;
  email: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private io: IoService
  ) {
    this.resetPasswordFrm = this.fb.group({
      otp_: [null, Validators.compose([Validators.required])],
      password_: [null, Validators.compose([Validators.required])],
      confirmpassword_: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    // this.resetPasswordFrm.get('otp_').setValue(localStorage.getItem('otp'));
    this.io.currentMessage.subscribe((message) => {
      console.log(message);

      if (message) {
        this.email = message;
      }
    });
  }

  get f() {
    return this.resetPasswordFrm.controls;
  }

  restPassWord(value) {
    if (!value.otp_) {
      swal.fire({
        title: "Oops...",
        text: "Please insert OTP.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!value.password_) {
      swal.fire({
        title: "Oops...",
        text: "Please input password.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (!value.confirmpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Please confirm password.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    if (value.password_ !== value.confirmpassword_) {
      swal.fire({
        title: "Oops...",
        text: "Passwords do not match.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }
    let payload = {
      email: this.email,
      password: this.resetPasswordFrm.value.password_,
      otp: this.resetPasswordFrm.value.otp_.toString(),
    };
    this.io
      .apicall(payload, "restaurantowner/reset-password", "post")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          this.router.navigate(["/login"]);
        }
      })
      .catch((err) => {
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }

  showHide() {
    $(".showHide-password").each(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }
}
