import { Component, Input, OnInit } from "@angular/core";
import { Menu } from '../../../interfaces/menu.interface';

@Component({
  selector: "app-menu-item",
  templateUrl: "./menu-item.component.html",
  styleUrls: ["./menu-item.component.css"],
})
export class MenuItemComponent implements OnInit {
  @Input() menu: Menu
  @Input() isDeliverect: boolean

  showDetails = false

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
