import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DataService } from "../../services/data-routing/data.service";
import { IoService } from "../../services/http/io.service";

@Component({
  selector: "app-branch-list",
  templateUrl: "./branch-list.component.html",
  styleUrls: ["./branch-list.component.css"],
})
export class BranchListComponent implements OnInit, OnDestroy {
  page: number;
  total: number;
  branchList: any = [];
  resturantName: any = "";
  paramObserver: Subscription;

  constructor(
    private ioservice: IoService,
    private router: Router,
    private route: ActivatedRoute,
    private setData: DataService
  ) {}

  ngOnInit(): void {
    this.resturantName = JSON.parse(localStorage.getItem("restaurant"));
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      let currentPage = values.page;
      if (currentPage) {
        this.page = currentPage;
      } else {
        this.page = 1;
      }
      this.allBranchList();
    });
    this.allBranchList();
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  allBranchList() {
    this.branchList = [];
    let payLoad = {
      page: this.page,
      limit: 5,
      parentId: this.resturantName?.id,
      orderName: "restaurantName",
      orderType: 1,
    };
    this.ioservice
      .apicall(payLoad, "restaurant/restaurant-branch-list", "post")
      .then((res: any) => {
        if (res["serverResponse"].code === 200) {
          const result = res["result"];
          this.total = result.totalRecords;
          if (this.total > this.branchList.length) {
            this.branchList = [...this.branchList, ...result.restaurantList];
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  pageChanged(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { page: event },
      queryParamsHandling: "merge",
    });
    if (event >= 1) {
      this.allBranchList();
    }
  }
}
