import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { IoService } from "../../services/http/io.service";
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { base64ToFile } from "ngx-image-cropper";
import swal from "sweetalert2";
import { ActivatedRoute, Router } from "@angular/router";
import { DishService } from "./../../services/dish/dish.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ThemeService } from "ng2-charts";
import { environment } from "../../../environments/environment";
import { forkJoin, Subscription } from "rxjs";
import { DietaryPreferencesService } from "./../../services/dietaryPreferences/dietary-preferences.service";
import { TagManagementService } from "./../../services/tagManagement/tag-management.service";

@Component({
  selector: "app-add-dish",
  templateUrl: "./add-dish.component.html",
  styleUrls: ["./add-dish.component.css"],
})
export class AddDishComponent implements OnInit, OnDestroy {
  dropdownSettings: IDropdownSettings;
  addDishFrm: FormGroup;
  croppedImage: any = "";
  image: any = "";
  file: any;
  closeResult = "";
  imageChangedEvent = "";
  finalFile: any = "";
  enableCropper = false;
  transform: ImageTransform = {};
  allHeadList: any = [];
  foodTypeHeadId: any = "";
  foodTypeList: any = [];
  mealTimeHeadId: any = "";
  mealTimeList: any = [];
  cuisineHeadId: any = "";
  cuisineList: any = [];
  mealTimeSelectUnselect = [];
  cuisineSelectUnselect = [];
  foodTypeSelectUnselect = [];
  allListedItem = [];
  restaurantName: any;
  restaurantId: any;
  discountArray: any = [];
  discountType: any;
  finalAddTagArr: any = [];
  selectedDiscountType: any = "flat";
  discount_: number = 0;
  duplicateId: any = null;
  paramObserver: Subscription;
  dietaryPreferences: any = [];
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private io: IoService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private DishService: DishService,
    private loader: NgxUiLoaderService,
    private dietaryPreferencesService: DietaryPreferencesService,
    private tagManagementService: TagManagementService
  ) {}

  ngOnInit(): void {
    this.paramObserver = this.route.queryParams.subscribe((values) => {
      this.duplicateId = values.duplicate || null;
      this.loadDropDown();
      this.readyAddDishFrm();
      this.fetchAllHead();
      this.loadDiscountType();
    });
  }

  ngOnDestroy(): void {
    this.paramObserver.unsubscribe();
  }

  loadDiscountType() {
    return this.discountArray.push(
      { id: 0, discountType: "flat" },
      { id: 1, discountType: "percentage" }
    );
  }

  discountTypeOnChange() {
    this.discount_ = this.addDishFrm.value.discount;
    let price = this.addDishFrm.value.price;
    let discount = this.addDishFrm.value.discount;
    let netPrice: any = 0;
    let currentDiscountType = this.addDishFrm.value.discountType
      ? this.addDishFrm.value.discountType
      : this.selectedDiscountType;
    if (currentDiscountType === "flat") {
      netPrice = (price - discount).toFixed(2);
      this.addDishFrm.get("netprice").setValue(netPrice);
    } else if (currentDiscountType === "percentage") {
      netPrice = (price - (price * discount) / 100).toFixed(2);
      this.addDishFrm.get("netprice").setValue(netPrice);
    } else {
      netPrice = price;
    }
  }

  getRestaurantAndDishData() {
    if (this.duplicateId) {
      this.loader.start();
      this.DishService.getDish(this.duplicateId).subscribe(
        (res) => {
          if (res?.serverResponse?.code == 200) {
            let data = res["result"][0];
            this.restaurantName = data.restaurantName;
            this.restaurantId = data.restaurantId;
            this.prefillForm(data);
          }
          this.loader.stop();
        },
        () => {
          this.loader.stop();
          this.router.navigate(["/dish/dish-list"]);
        }
      );
    } else {
      let resObj = JSON.parse(localStorage.getItem("restaurant"));
      this.restaurantName = resObj?.restaurantName;
      this.restaurantId = resObj?.id;
      if (
        this.restaurantId === "undefined" &&
        this.restaurantName === "undefined"
      ) {
        this.router.navigate(["/dish/dish-list"]);
      }
    }
  }

  prefillForm(data): void {
    this.addDishFrm.patchValue({
      dishName: data.dishName,
      carbs: data.carbs,
      protin: data.protein,
      fat: data.fat,
      totalCal: data.totalCalories,
      price: data.price,
      newDish: data.IsNew,
      dishDetails: data.dishdetails,
      discount: data.discount,
      discountType: data.discountType,
      netprice: data.netPrice,
    });
    this.image = data.discImage;
    this.allListedItem = this.parseListItems(data.nutritionalInfo);
    this.foodTypeSelectUnselect = this.parseListItems(data.headTagInfo[4].tags);
    this.cuisineSelectUnselect = this.parseListItems(data.headTagInfo[3].tags);
    this.mealTimeSelectUnselect = this.parseListItems(data.headTagInfo[2].tags);
    this.dietaryPreferences.forEach((item) =>
      this.addDishFrm
        .get(item?.name)
        .patchValue(
          data?.dietaryPreferences[item?.id]?.map((mapItem) =>
            item.options.find((searchItem) => searchItem.id == mapItem)
          )
        )
    );
  }

  parseListItems(list): Array<any> {
    return list.map((item) => {
      return { tagName: item.tagName, id: item.tagId };
    });
  }

  loadDropDown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "tagName",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      allowSearchFilter: true,
    };
  }

  readyAddDishFrm() {
    this.addDishFrm = this.fb.group({
      dishName: ["", Validators.required],
      carbs: ["", Validators.compose([Validators.required])],
      protin: ["", Validators.compose([Validators.required])],
      fat: ["", Validators.compose([Validators.required])],
      totalCal: ["", Validators.compose([Validators.required])],
      price: ["", Validators.compose([Validators.required])],
      newDish: [false, Validators.compose([Validators.required])],
      dishDetails: ["", Validators.compose([Validators.required])],
      discount: [0, Validators.compose([Validators.required])],
      discountType: [""],
      netprice: [
        "",
        Validators.compose([Validators.required, Validators.min(2)]),
      ],
    });
  }

  setDiscountTypeValidator(): void {
    const discountControl = this.addDishFrm.get("discount");
    const discountTypeControl = this.addDishFrm.get("discountType");

    discountControl.valueChanges.subscribe((value) => {
      if (value !== "" && value > 0) {
        discountTypeControl.setValidators([Validators.required]);
      } else {
        discountTypeControl.setValidators(null);
      }
    });
  }

  add(Obj) {}

  openModal(content) {
    this.imageChangedEvent = "";
    this.croppedImage = "";
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          return reason;
        }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
    this.enableCropper = false;
  }

  //================================ 'image upload for dish '===================================================================//

  fileChangeEvent(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.image = reader.result as string;
      };
      this.file = event.target.files[0];
      reader.readAsDataURL(event.target.files[0]);
      this.imageChangedEvent = event;
      this.image = this.imageChangedEvent;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.finalFile = base64ToFile(this.croppedImage);
    this.enableCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log("Cropper ready", sourceImageDimensions);
  }

  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }

  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  async saveImage(): Promise<any> {
    this.loader.start();
    return new Promise((resolve, reject) => {
      const fd = new FormData();
      fd.append("file", this.finalFile);
      this.io
        .apicall(fd, "uploads/dish-img-upload", "post")
        .then((res: any) => {
          this.loader.stop();
          if (res["serverResponse"].code === 200) {
            this.image = res["result"][0].fileUrl;
            resolve(res["result"][0].fileUrl);
            this.modalService.dismissAll();
          }
        })
        .catch((err) => {
          this.loader.stop();
          swal.fire({
            title: "Oops...",
            text:
              this.io?.data_?.serverResponse?.message ||
              "Something went wrong while uploading image",
            icon: "warning",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
        });
    });
  }

  //================================= 'end of dish upload'  ========================================================================//

  //================================== 'section for dropdown' ======================================================================//
  fetchAllHead() {
    const tag_subscriber = this.tagManagementService.listCategories();
    const diet_subscriber = this.dietaryPreferencesService.listCategories();
    forkJoin({
      tagRes: tag_subscriber,
      dietRes: diet_subscriber,
    }).subscribe(async ({ tagRes, dietRes }) => {
      let categories_subscribers: any = {};
      if (tagRes["serverResponse"].code === 200) {
        this.allHeadList = tagRes["result"];
        for (var i = 0; i < this.allHeadList.length; i++) {
          if (this.allHeadList[i].tagHeadId === "H6") {
            this.headTagMealTime(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H4") {
            this.headTagFoodType(this.allHeadList[i].id);
          } else if (this.allHeadList[i].tagHeadId === "H3") {
            this.headTagCusine(this.allHeadList[i].id);
          }
        }
      }
      this.dietaryPreferences = dietRes;
      this.dietaryPreferences.forEach((item) => {
        categories_subscribers[item?.id] =
          this.dietaryPreferencesService.getCategoryOptions(item.id);
        item.dropdownSettings = {
          ...this.dropdownSettings,
          singleSelection: !item.multi && !(item.name=='Macronutrient Ratio'),
          textField: "name",
          idField: "id",
        };
        const diet_control = new FormControl([]);
        this.addDishFrm.addControl(item.name, diet_control);
      });
      forkJoin(categories_subscribers).subscribe((data) => {
        this.handleDietaryPreferencesCategories(data);
      });
    });
  }

  handleDietaryPreferencesCategories(data) {
    this.dietaryPreferences.forEach((item, index) => {
      item.options = data[item.id];
      if (item.selectionType == "exclude") {
        this.finalAddTagArr = this.finalAddTagArr.concat(
          item.options.map((mapItem) => {
            return {
              ...mapItem,
              tagName: mapItem.name + " Free",
              id: mapItem.id,
            };
          })
        );
        this.handleExcludePreference(item.name, index);
      }
    });
    this.getRestaurantAndDishData();
  }

  handleExcludePreference(controlName: string, index: number): void {
    const field_control = this.addDishFrm.get(controlName);
    field_control.valueChanges.subscribe((value) => {
      this.allListedItem = this.allListedItem.filter(
        (filteredItem) =>
          !value.find((searchItem) => searchItem?.tagid == filteredItem.tagId)
      );
      let options = [...this.dietaryPreferences[index].options];

      this.finalAddTagArr = options
        .filter(
          (filteredItem) =>
            !value.find((searchItem) => searchItem.id == filteredItem.id)
        )
        .map((item) => {
          return { ...item, tagName: item.name + " Free", id: item.id };
        });
    });
  }

  async headTagFoodType(Obj) {
    this.foodTypeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    // let myArr1 = [];
    // for (let tags of value) {
    //   myArr1.push({ tagId: tags.id, tagName: tags.tagName });
    // }
    this.foodTypeList = value;
  }

  async headTagMealTime(Obj) {
    this.mealTimeHeadId = Obj;
    let value = await this.giveTagList(Obj);
    // let myArr3 = [];
    // for (let tags of value) {
    //   myArr3.push({ tagId: tags.id, tagName: tags.tagName });
    // }
    this.mealTimeList = value;
  }

  async headTagCusine(Obj) {
    this.cuisineHeadId = Obj;
    let value = await this.giveTagList(Obj);
    // let myArr4 = [];
    // for (let tags of value) {
    //   myArr4.push({ tagId: tags.id, tagName: tags.tagName });
    // }
    this.cuisineList = value;
  }

  async giveTagList(Obj): Promise<any> {
    return new Promise((resolve, reject) => {
      let payLoad = {
        tagHeadId: Obj,
      };
      this.io
        .apicall(payLoad, "tagcategories/fetch-tag-by-head", "post")
        .then((res: any) => {
          if (res["serverResponse"].code === 200) {
            resolve(res["result"]);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //================================================='start of meal time dropdown ' =========================================================//

  onItemSelect2(item: any) {
    this.mealTimeSelectUnselect.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll2(items: any) {
    this.mealTimeSelectUnselect = [];
    for (var i = 0; i < items.length; i++) {
      this.mealTimeSelectUnselect.push({
        id: items[i].id,
        tagName: items[i].tagName,
      });
    }
  }

  onItemDeSelect2(event) {
    this.mealTimeSelectUnselect.splice(
      this.mealTimeSelectUnselect.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll2(event) {
    this.mealTimeSelectUnselect = [];
  }

  //=================================================' end of meal time dropdown ' ==========================================================//
  //=================================================' start of cusine dropdown ' ==========================================================//

  onItemSelect3(item: any) {
    this.cuisineSelectUnselect.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll3(items: any) {
    this.cuisineSelectUnselect = [];
    for (var i = 0; i < items.length; i++) {
      this.cuisineSelectUnselect.push({
        id: items[i].id,
        tagName: items[i].tagName,
      });
    }
  }

  onItemDeSelect3(event) {
    this.cuisineSelectUnselect.splice(
      this.cuisineSelectUnselect.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll3(event) {
    this.cuisineSelectUnselect = [];
  }

  //================================================' end of cusine dropdown ' ==============================================================//
  //================================================' start of food type dropdown '=============================================//
  onItemSelect4(item: any) {
    this.foodTypeSelectUnselect.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll4(items: any) {
    this.foodTypeSelectUnselect = [];
    for (var i = 0; i < items.length; i++) {
      this.foodTypeSelectUnselect.push({
        id: items[i].id,
        tagName: items[i].tagName,
      });
    }
  }

  onItemDeSelect4(event) {
    this.foodTypeSelectUnselect.splice(
      this.foodTypeSelectUnselect.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll4(event) {
    this.foodTypeSelectUnselect = [];
  }
  //================================================' end of all allergers containeted dropdoown ' ==========================================//
  //================================================' all tag section dropdown '=============================================================//

  onItemSelect6(item: any) {
    this.allListedItem.push({
      id: item.id,
      tagName: item.tagName,
    });
  }

  onSelectAll6(items: any) {
    this.allListedItem = [];
    for (var i = 0; i < items.length; i++) {
      this.allListedItem.push({
        id: items[i].id,
        tagName: items[i].tagName,
      });
    }
  }

  onItemDeSelect6(event) {
    this.allListedItem.splice(
      this.allListedItem.findIndex((tier) => tier.id === event.id),
      1
    );
  }

  onDeSelectAll6(event) {
    this.allListedItem = [];
  }

  //=================================== ' end of dropdown section ' ========================================================================//

  save() {
    this.modalService.dismissAll();
  }

  async addDish(Obj) {
    if (!this.addDishFrm.valid) {
      swal.fire({
        title: "Oops...",
        text: "Please make sure you filled all required fields correctly",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      this.addDishFrm.markAllAsTouched();
      return;
    }
    if (!this.image) {
      swal.fire({
        title: "Oops...",
        text: "Please add dish image.",
        icon: "warning",
        confirmButtonColor: "#442DFF;",
        confirmButtonText: "ok",
      });
      return 0;
    }

    if (this.finalFile != "") {
      await this.saveImage();
    }

    this.loader.start();

    let dietary_preferences = {};
    this.dietaryPreferences.forEach((item) => {
      dietary_preferences[item.id] = this.addDishFrm
        .get(item.name)
        .value.map((preference) => preference.id);
    });

    var payLoad = {
      dishName: Obj.dishName,
      restaurantId: this.restaurantId,
      // prepTime: parseInt(Obj.prepTime),
      carbs: Obj.carbs,
      fat: Obj.fat,
      protein: Obj.protin,
      totalCalories: Obj.totalCal,
      IsNew: Obj.newDish,
      price: Obj.price,
      nutritionalInfo: this.allListedItem,
      discImage: this.image,
      dishdetails: Obj.dishDetails,
      discountType: Obj.discountType ? Obj.discountType : "flat",
      discount: Obj.discount ? Obj.discount : 0,
      netPrice: Obj.netprice,
      headTagInfo: [
        {
          headId: this.mealTimeHeadId,
          tags: this.mealTimeSelectUnselect,
        },
        {
          headId: this.cuisineHeadId,
          tags: this.cuisineSelectUnselect,
        },
        {
          headId: this.foodTypeHeadId,
          tags: this.foodTypeSelectUnselect,
        },
      ],
      dietaryPreferences: dietary_preferences,
    };

    let convToText = JSON.stringify(payLoad.headTagInfo);
    let srchAndReplace = convToText.split("id").join("tagId");
    let convToJson = JSON.parse(srchAndReplace);
    payLoad.headTagInfo = convToJson;

    let convToText1 = JSON.stringify(payLoad.nutritionalInfo);
    let srchAndReplace1 = convToText1.split("id").join("tagId");
    let convToJson1 = JSON.parse(srchAndReplace1);
    payLoad.nutritionalInfo = convToJson1;

    this.io
      .apicall(payLoad, "dish/add-dish", "post")
      .then((res) => {
        if (this.io.data_.serverResponse.code == 200) {
          this.loader.stop();
          swal.fire({
            title: "Success",
            text: res["serverResponse"].message,
            icon: "success",
            confirmButtonColor: "#442DFF;",
            confirmButtonText: "ok",
          });
          this.router.navigate(["/dish/dish-list"]);
          this.addDishFrm.reset();
        }
      })
      .catch((err) => {
        this.loader.stop();
        swal.fire({
          title: "Oops...",
          text: this.io.data_.serverResponse.message,
          icon: "warning",
          confirmButtonColor: "#442DFF;",
          confirmButtonText: "ok",
        });
      });
  }
}
