import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TransactionRoutingModule } from "./transaction-routing.module";
import { TransactionComponent } from "./transaction.component";
import { NgxPaginationModule } from "ngx-pagination";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "../modal/modal.module";
import { RestaurantTransactionPayoutViewDetailsComponent } from ".";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

@NgModule({
  declarations: [
    TransactionComponent,
    RestaurantTransactionPayoutViewDetailsComponent,
  ],
  imports: [
    CommonModule,
    TransactionRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    NgxDaterangepickerMd.forRoot(),
  ],
})
export class TransactionModule {}
