import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  ngOnInit() {
    this.forceReloadAfterTimeout();
  }

  private forceReloadAfterTimeout() {
    const ONE_HOUR_MS = 3600000;

    setTimeout(() => window.location.reload(), ONE_HOUR_MS);
  }
}
