import { Component, Input, OnInit } from "@angular/core";
import { SubProduct } from '../../../interfaces/sub-product.interface';

@Component({
  selector: "app-sub-product",
  templateUrl: "./sub-product.component.html",
  styleUrls: ["./sub-product.component.css"],
})
export class SubProductComponent implements OnInit {
  @Input() product: SubProduct

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
