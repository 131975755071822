import { Component, Input, OnInit } from "@angular/core";
import { MenuCategory } from '../../../interfaces/menu-category.interface';

@Component({
  selector: "app-menu-category",
  templateUrl: "./menu-category.component.html",
  styleUrls: ["./menu-category.component.css"],
})
export class MenuCategoryComponent implements OnInit {
  @Input() category: MenuCategory

  showDetails = false

  constructor(
  ) { }

  ngOnInit(): void {
  }
}
